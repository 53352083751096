@import 'src/utilities/references/index';

.vekalapp-online-chat-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include vekalapp-md {
        margin-top: 48px;
        padding-right: 32px;
        height: calc(100vh - 81px - 48px - 12px);
    }
    @include vekalapp-pre-md {
        padding: 24px 22px 100px 22px;
        height: 100vh;
    }

    &.mobile-chat-detail {
        @include vekalapp-pre-md {
            padding: 24px 22px 22px 22px !important;
            z-index: 2000000;
            background-color: $bg1;
        }
    }

    .page-header {
        @include vekalapp-pre-md {
            flex-direction: column;
            align-items: normal;
        }
        @include vekalapp-md {
            margin-bottom: 32px;
        }
        display: flex;
        align-items: center;

        > div {
            @include vekalapp-pre-md {
                flex-direction: row;
                align-items: center;
                margin-bottom: 24px;
            }
            display: flex;
            flex-direction: column;
            flex: 1;
            > a > button {
                width: 45px;
            }
            > a {
                margin-left: 12px;
            }
        }
        > button {
            width: auto;
            flex-shrink: 0;
        }

        .title {
            @include vekalapp-size(font-size, 24px, 22px, true);
            color: $text4;
            font-weight: 500;
            flex: 1;
        }
    }

    .chat-context {
        display: flex;
        flex: 1;
        column-gap: 26px;
        max-height: 100%;
        overflow: hidden;
        position: relative;
        flex-direction: column;
        @include vekalapp-lg {
            flex-direction: row;
        }

        .right-sidebar {
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: hidden;
            max-width: unset;
            @include vekalapp-lg {
                max-width: 290px;
            }
            @include vekalapp-pre-md {
                flex: 1;
            }
            padding: 0;

            .vekalapp-text-input {
                input {
                    height: 52px !important;
                    margin-top: 0;
                    font-size: 18px !important;

                    &::placeholder {
                        font-size: 18px !important;
                    }
                }
            }

            .search-field {
                height: 52px;
                margin-bottom: 26px;
                font-size: 18px;
                font-weight: normal;
            }

            .chat-list {
                padding: 4px;
                border-radius: 5px;
                background-color: #fff;
                row-gap: 10px;
                -ms-overflow-style: none; /* for Internet Explorer, Edge */
                scrollbar-width: none; /* for Firefox */
                overflow-y: scroll;
                flex: 1;
                @include vekalapp-pre-md {
                    max-height: 100%;
                }

                &::-webkit-scrollbar {
                    display: none; /* for Chrome, Safari, and Opera */
                }

                .es-users-allow {
                    display: flex;
                    justify-content: center;
                    height: 100%;

                    p {
                        padding: 30px;
                        font-size: 14px;
                        font-weight: normal;
                        color: $text3;
                        width: 100%;
                        text-align: center;
                    }
                }

                .items {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    column-gap: 10px;
                    cursor: pointer;

                    &.active {
                        background-color: $bg1;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        border-bottom: 2px solid $accentLightBlue;
                    }

                    img {
                        width: 45px;
                        height: 45px;
                        border-radius: 100%;
                    }

                    .chat-detail {
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        width: 100%;

                        .brief-message {
                            display: flex;
                            overflow: hidden;
                            align-items: center;

                            .spacer {
                                display: flex;
                                flex: 1;
                                min-width: 10px;
                            }

                            .msg {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 11px;
                                font-weight: normal;
                                color: #8b8b9a;
                            }

                            .unread-messages-count {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 100%;
                                width: 15px;
                                height: 15px;
                                background-color: #fe668b;
                                font-size: 10px;
                                color: #ffffff;
                                font-weight: normal;
                            }
                        }

                        .name-date {
                            display: flex;
                            align-items: center;

                            .spacer {
                                flex: 1;
                                display: flex;
                                width: 100%;
                            }

                            .date-time {
                                font-size: 10px;
                                font-weight: normal;
                                color: #8b8b9a;
                            }

                            .name {
                                font-size: 14px;
                                font-weight: normal;
                                color: $text4;
                            }
                        }
                    }
                }
            }
        }

        .pvp-chat {
            display: flex;
            border-radius: 5px;
            background-color: #fff;
            padding: 16px;
            overflow: hidden;
            flex-grow: 1;
            flex-direction: column;
            position: relative;

            .header {
                display: flex;
                column-gap: 10px;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 100%;
                }

                .name-job {
                    display: flex;
                    flex-direction: column;

                    .job {
                        font-size: 11px;
                        font-weight: normal;
                        color: #8b8b9a;
                    }

                    .name {
                        @include vekalapp-size(font-size, 14px, 12px);
                        font-weight: normal;
                        color: $text4;
                        margin-bottom: 2px;
                    }
                }

                .spacer {
                    display: flex;
                    width: 100%;
                    flex: 1;
                }

                button {
                    display: flex;
                    width: 40px;
                    height: 40px;
                    align-items: center;
                    justify-content: center;
                    outline: none;
                    border: none;
                    border-radius: 5px;
                    background-color: $bg1;
                    cursor: pointer;

                    i {
                        @include vekalapp-size(font-size, 22px, 16px);
                        color: $text4;

                        &::selection {
                            display: none;
                        }

                        &.blue {
                            color: $accentBlue;
                        }
                    }
                }
            }

            .custom-line {
                height: 2.5px;
                background-color: $bg1;
                margin: 12px 0;
            }

            .chat-box {
                //row-gap: 12px;
                //overflow: auto;
                //max-height: 500px;
                //display: none;
                display: flex;
                flex-direction: column-reverse;
                flex-grow: 1;
                position: relative;

                -ms-overflow-style: none; /* for Internet Explorer, Edge */
                scrollbar-width: none; /* for Firefox */
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    display: none; /* for Chrome, Safari, and Opera */
                }

                .started-chat-date {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 12px;

                    p {
                        border-radius: 5px;
                        font-size: 12px;
                        font-weight: normal;
                        padding: 5px 10px;
                        color: $text4;
                        background-color: #f1f2f5;
                    }
                }
            }

            .footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                column-gap: 12px;
                margin-top: 22px;
                width: 100%;

                .vekalapp-text-input {
                    input {
                        margin-top: 0 !important;
                    }
                }

                i {
                    @include vekalapp-size(font-size, 22px, 16px);
                    color: #200e32;
                    cursor: pointer;
                    //color: rgba(#8b8b9a, 0.56);

                    &.rotate-icon {
                        transform: rotate(225deg);
                        color: rgba(#8b8b9a, 0.56);
                    }

                    &::selection {
                        display: none;
                    }
                }
            }

            .is-uploaded-attachment {
                display: flex;
                margin-bottom: -20px;
                width: 100%;
                align-items: center;
                padding: 10px 10px;
                border-radius: 5px;
                animation: attachment_uploaded_animation 0.3s ease-in-out;
                background-color: rgba($accentLightBlue, 0.23);
                transform-origin: bottom;

                @keyframes attachment_uploaded_animation {
                    0% {
                        transform: scaleY(0);
                    }

                    100% {
                        transform: scaleY(1);
                    }
                }

                p {
                    font-weight: normal;
                    font-size: 16px;
                    color: $text4;
                    margin-right: 12px;
                }

                .spacer {
                    display: flex;
                    flex: 1;
                    width: 100%;
                    min-width: 50px;
                }

                i {
                    font-size: 20px;
                    color: $text4;
                    border-radius: 100%;
                    padding: 5px;
                    background-color: $bg1;
                    cursor: pointer;
                }
            }
        }

        .es-start-chat {
            display: flex;
            flex-direction: column;
            flex: 1 1;

            p {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                @include vekalapp-size(font-size, 20px, 14px);
                background-color: #ffffff;
                font-weight: normal;
                padding: 0 25px;
                text-align: center;
                color: rgba($text2, 0.85);
                border-radius: 5px;
            }
        }
    }
}
