@font-face {
  font-family: "feather";
  src: url('./feather.eot?t=1525787366991');
  /* IE9*/
  src: url('./feather.eot?t=1525787366991#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./feather.woff?t=1525787366991') format('woff'),
    /* chrome, firefox */
    url('./feather.ttf?t=1525787366991') format('truetype'),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
    url('./feather.svg?t=1525787366991#feather') format('svg');
  /* iOS 4.1- */
}

.cfi {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'feather' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cfi-alert-octagon:before {
  content: "\e81b";
}

.cfi-alert-circle:before {
  content: "\e81c";
}

.cfi-activity:before {
  content: "\e81d";
}

.cfi-alert-triangle:before {
  content: "\e81e";
}

.cfi-align-center:before {
  content: "\e81f";
}

.cfi-airplay:before {
  content: "\e820";
}

.cfi-align-justify:before {
  content: "\e821";
}

.cfi-align-left:before {
  content: "\e822";
}

.cfi-align-right:before {
  content: "\e823";
}

.cfi-arrow-down-left:before {
  content: "\e824";
}

.cfi-arrow-down-right:before {
  content: "\e825";
}

.cfi-anchor:before {
  content: "\e826";
}

.cfi-aperture:before {
  content: "\e827";
}

.cfi-arrow-left:before {
  content: "\e828";
}

.cfi-arrow-right:before {
  content: "\e829";
}

.cfi-arrow-down:before {
  content: "\e82a";
}

.cfi-arrow-up-left:before {
  content: "\e82b";
}

.cfi-arrow-up-right:before {
  content: "\e82c";
}

.cfi-arrow-up:before {
  content: "\e82d";
}

.cfi-award:before {
  content: "\e82e";
}

.cfi-bar-chart:before {
  content: "\e82f";
}

.cfi-at-sign:before {
  content: "\e830";
}

.cfi-bar-chart-2:before {
  content: "\e831";
}

.cfi-battery-charging:before {
  content: "\e832";
}

.cfi-bell-off:before {
  content: "\e833";
}

.cfi-battery:before {
  content: "\e834";
}

.cfi-bluetooth:before {
  content: "\e835";
}

.cfi-bell:before {
  content: "\e836";
}

.cfi-book:before {
  content: "\e837";
}

.cfi-briefcase:before {
  content: "\e838";
}

.cfi-camera-off:before {
  content: "\e839";
}

.cfi-calendar:before {
  content: "\e83a";
}

.cfi-bookmark:before {
  content: "\e83b";
}

.cfi-box:before {
  content: "\e83c";
}

.cfi-camera:before {
  content: "\e83d";
}

.cfi-check-circle:before {
  content: "\e83e";
}

.cfi-check:before {
  content: "\e83f";
}

.cfi-check-square:before {
  content: "\e840";
}

.cfi-cast:before {
  content: "\e841";
}

.cfi-chevron-down:before {
  content: "\e842";
}

.cfi-chevron-left:before {
  content: "\e843";
}

.cfi-chevron-right:before {
  content: "\e844";
}

.cfi-chevron-up:before {
  content: "\e845";
}

.cfi-chevrons-down:before {
  content: "\e846";
}

.cfi-chevrons-right:before {
  content: "\e847";
}

.cfi-chevrons-up:before {
  content: "\e848";
}

.cfi-chevrons-left:before {
  content: "\e849";
}

.cfi-circle:before {
  content: "\e84a";
}

.cfi-clipboard:before {
  content: "\e84b";
}

.cfi-chrome:before {
  content: "\e84c";
}

.cfi-clock:before {
  content: "\e84d";
}

.cfi-cloud-lightning:before {
  content: "\e84e";
}

.cfi-cloud-drizzle:before {
  content: "\e84f";
}

.cfi-cloud-rain:before {
  content: "\e850";
}

.cfi-cloud-off:before {
  content: "\e851";
}

.cfi-codepen:before {
  content: "\e852";
}

.cfi-cloud-snow:before {
  content: "\e853";
}

.cfi-compass:before {
  content: "\e854";
}

.cfi-copy:before {
  content: "\e855";
}

.cfi-corner-down-right:before {
  content: "\e856";
}

.cfi-corner-down-left:before {
  content: "\e857";
}

.cfi-corner-left-down:before {
  content: "\e858";
}

.cfi-corner-left-up:before {
  content: "\e859";
}

.cfi-corner-up-left:before {
  content: "\e85a";
}

.cfi-corner-up-right:before {
  content: "\e85b";
}

.cfi-corner-right-down:before {
  content: "\e85c";
}

.cfi-corner-right-up:before {
  content: "\e85d";
}

.cfi-cpu:before {
  content: "\e85e";
}

.cfi-credit-card:before {
  content: "\e85f";
}

.cfi-crosshair:before {
  content: "\e860";
}

.cfi-disc:before {
  content: "\e861";
}

.cfi-delete:before {
  content: "\e862";
}

.cfi-download-cloud:before {
  content: "\e863";
}

.cfi-download:before {
  content: "\e864";
}

.cfi-droplet:before {
  content: "\e865";
}

.cfi-edit-2:before {
  content: "\e866";
}

.cfi-edit:before {
  content: "\e867";
}

.cfi-edit-1:before {
  content: "\e868";
}

.cfi-external-link:before {
  content: "\e869";
}

.cfi-eye:before {
  content: "\e86a";
}

.cfi-feather:before {
  content: "\e86b";
}

.cfi-facebook:before {
  content: "\e86c";
}

.cfi-file-minus:before {
  content: "\e86d";
}

.cfi-eye-off:before {
  content: "\e86e";
}

.cfi-fast-forward:before {
  content: "\e86f";
}

.cfi-file-text:before {
  content: "\e870";
}

.cfi-film:before {
  content: "\e871";
}

.cfi-file:before {
  content: "\e872";
}

.cfi-file-plus:before {
  content: "\e873";
}

.cfi-folder:before {
  content: "\e874";
}

.cfi-filter:before {
  content: "\e875";
}

.cfi-flag:before {
  content: "\e876";
}

.cfi-globe:before {
  content: "\e877";
}

.cfi-grid:before {
  content: "\e878";
}

.cfi-heart:before {
  content: "\e879";
}

.cfi-home:before {
  content: "\e87a";
}

.cfi-github:before {
  content: "\e87b";
}

.cfi-image:before {
  content: "\e87c";
}

.cfi-inbox:before {
  content: "\e87d";
}

.cfi-layers:before {
  content: "\e87e";
}

.cfi-info:before {
  content: "\e87f";
}

.cfi-instagram:before {
  content: "\e880";
}

.cfi-layout:before {
  content: "\e881";
}

.cfi-link-2:before {
  content: "\e882";
}

.cfi-life-buoy:before {
  content: "\e883";
}

.cfi-link:before {
  content: "\e884";
}

.cfi-log-in:before {
  content: "\e885";
}

.cfi-list:before {
  content: "\e886";
}

.cfi-lock:before {
  content: "\e887";
}

.cfi-log-out:before {
  content: "\e888";
}

.cfi-loader:before {
  content: "\e889";
}

.cfi-mail:before {
  content: "\e88a";
}

.cfi-maximize-2:before {
  content: "\e88b";
}

.cfi-map:before {
  content: "\e88c";
}

.cfi-map-pin:before {
  content: "\e88e";
}

.cfi-menu:before {
  content: "\e88f";
}

.cfi-message-circle:before {
  content: "\e890";
}

.cfi-message-square:before {
  content: "\e891";
}

.cfi-minimize-2:before {
  content: "\e892";
}

.cfi-mic-off:before {
  content: "\e893";
}

.cfi-minus-circle:before {
  content: "\e894";
}

.cfi-mic:before {
  content: "\e895";
}

.cfi-minus-square:before {
  content: "\e896";
}

.cfi-minus:before {
  content: "\e897";
}

.cfi-moon:before {
  content: "\e898";
}

.cfi-monitor:before {
  content: "\e899";
}

.cfi-more-vertical:before {
  content: "\e89a";
}

.cfi-more-horizontal:before {
  content: "\e89b";
}

.cfi-move:before {
  content: "\e89c";
}

.cfi-music:before {
  content: "\e89d";
}

.cfi-navigation-2:before {
  content: "\e89e";
}

.cfi-navigation:before {
  content: "\e89f";
}

.cfi-octagon:before {
  content: "\e8a0";
}

.cfi-package:before {
  content: "\e8a1";
}

.cfi-pause-circle:before {
  content: "\e8a2";
}

.cfi-pause:before {
  content: "\e8a3";
}

.cfi-percent:before {
  content: "\e8a4";
}

.cfi-phone-call:before {
  content: "\e8a5";
}

.cfi-phone-forwarded:before {
  content: "\e8a6";
}

.cfi-phone-missed:before {
  content: "\e8a7";
}

.cfi-phone-off:before {
  content: "\e8a8";
}

.cfi-phone-incoming:before {
  content: "\e8a9";
}

.cfi-phone:before {
  content: "\e8aa";
}

.cfi-phone-outgoing:before {
  content: "\e8ab";
}

.cfi-pie-chart:before {
  content: "\e8ac";
}

.cfi-play-circle:before {
  content: "\e8ad";
}

.cfi-play:before {
  content: "\e8ae";
}

.cfi-plus-square:before {
  content: "\e8af";
}

.cfi-plus-circle:before {
  content: "\e8b0";
}

.cfi-plus:before {
  content: "\e8b1";
}

.cfi-pocket:before {
  content: "\e8b2";
}

.cfi-printer:before {
  content: "\e8b3";
}

.cfi-power:before {
  content: "\e8b4";
}

.cfi-radio:before {
  content: "\e8b5";
}

.cfi-repeat:before {
  content: "\e8b6";
}

.cfi-refresh-ccw:before {
  content: "\e8b7";
}

.cfi-rewind:before {
  content: "\e8b8";
}

.cfi-rotate-ccw:before {
  content: "\e8b9";
}

.cfi-refresh-cw:before {
  content: "\e8ba";
}

.cfi-rotate-cw:before {
  content: "\e8bb";
}

.cfi-save:before {
  content: "\e8bc";
}

.cfi-search:before {
  content: "\e8bd";
}

.cfi-server:before {
  content: "\e8be";
}

.cfi-scissors:before {
  content: "\e8bf";
}

.cfi-share-2:before {
  content: "\e8c0";
}

.cfi-share:before {
  content: "\e8c1";
}

.cfi-shield:before {
  content: "\e8c2";
}

.cfi-settings:before {
  content: "\e8c3";
}

.cfi-skip-back:before {
  content: "\e8c4";
}

.cfi-shuffle:before {
  content: "\e8c5";
}

.cfi-sidebar:before {
  content: "\e8c6";
}

.cfi-skip-forward:before {
  content: "\e8c7";
}

.cfi-slack:before {
  content: "\e8c8";
}

.cfi-slash:before {
  content: "\e8c9";
}

.cfi-smartphone:before {
  content: "\e8ca";
}

.cfi-square:before {
  content: "\e8cb";
}

.cfi-speaker:before {
  content: "\e8cc";
}

.cfi-star:before {
  content: "\e8cd";
}

.cfi-stop-circle:before {
  content: "\e8ce";
}

.cfi-sun:before {
  content: "\e8cf";
}

.cfi-sunrise:before {
  content: "\e8d0";
}

.cfi-tablet:before {
  content: "\e8d1";
}

.cfi-tag:before {
  content: "\e8d2";
}

.cfi-sunset:before {
  content: "\e8d3";
}

.cfi-target:before {
  content: "\e8d4";
}

.cfi-thermometer:before {
  content: "\e8d5";
}

.cfi-thumbs-up:before {
  content: "\e8d6";
}

.cfi-thumbs-down:before {
  content: "\e8d7";
}

.cfi-toggle-left:before {
  content: "\e8d8";
}

.cfi-toggle-right:before {
  content: "\e8d9";
}

.cfi-trash-2:before {
  content: "\e8da";
}

.cfi-trash:before {
  content: "\e8db";
}

.cfi-trending-up:before {
  content: "\e8dc";
}

.cfi-trending-down:before {
  content: "\e8dd";
}

.cfi-triangle:before {
  content: "\e8de";
}

.cfi-type:before {
  content: "\e8df";
}

.cfi-twitter:before {
  content: "\e8e0";
}

.cfi-upload:before {
  content: "\e8e1";
}

.cfi-umbrella:before {
  content: "\e8e2";
}

.cfi-upload-cloud:before {
  content: "\e8e3";
}

.cfi-unlock:before {
  content: "\e8e4";
}

.cfi-user-check:before {
  content: "\e8e5";
}

.cfi-user-minus:before {
  content: "\e8e6";
}

.cfi-user-plus:before {
  content: "\e8e7";
}

.cfi-user-x:before {
  content: "\e8e8";
}

.cfi-user:before {
  content: "\e8e9";
}

.cfi-users:before {
  content: "\e8ea";
}

.cfi-video-off:before {
  content: "\e8eb";
}

.cfi-video:before {
  content: "\e8ec";
}

.cfi-voicemail:before {
  content: "\e8ed";
}

.cfi-volume-x:before {
  content: "\e8ee";
}

.cfi-volume-2:before {
  content: "\e8ef";
}

.cfi-volume-1:before {
  content: "\e8f0";
}

.cfi-volume:before {
  content: "\e8f1";
}

.cfi-watch:before {
  content: "\e8f2";
}

.cfi-wifi:before {
  content: "\e8f3";
}

.cfi-x-square:before {
  content: "\e8f4";
}

.cfi-wind:before {
  content: "\e8f5";
}

.cfi-x:before {
  content: "\e8f6";
}

.cfi-x-circle:before {
  content: "\e8f7";
}

.cfi-zap:before {
  content: "\e8f8";
}

.cfi-zoom-in:before {
  content: "\e8f9";
}

.cfi-zoom-out:before {
  content: "\e8fa";
}

.cfi-command:before {
  content: "\e8fb";
}

.cfi-cloud:before {
  content: "\e8fc";
}

.cfi-hash:before {
  content: "\e8fd";
}

.cfi-headphones:before {
  content: "\e8fe";
}

.cfi-underline:before {
  content: "\e8ff";
}

.cfi-italic:before {
  content: "\e900";
}

.cfi-bold:before {
  content: "\e901";
}

.cfi-crop:before {
  content: "\e902";
}

.cfi-help-circle:before {
  content: "\e903";
}

.cfi-paperclip:before {
  content: "\e904";
}

.cfi-shopping-cart:before {
  content: "\e905";
}

.cfi-tv:before {
  content: "\e906";
}

.cfi-wifi-off:before {
  content: "\e907";
}

.cfi-minimize:before {
  content: "\e88d";
}

.cfi-maximize:before {
  content: "\e908";
}

.cfi-gitlab:before {
  content: "\e909";
}

.cfi-sliders:before {
  content: "\e90a";
}

.cfi-star-on:before {
  content: "\e90b";
}

.cfi-heart-on:before {
  content: "\e90c";
}

.cfi-archive:before {
  content: "\e90d";
}

.cfi-arrow-down-circle:before {
  content: "\e90e";
}

.cfi-arrow-up-circle:before {
  content: "\e90f";
}

.cfi-arrow-left-circle:before {
  content: "\e910";
}

.cfi-arrow-right-circle:before {
  content: "\e911";
}

.cfi-bar-chart-line-:before {
  content: "\e912";
}

.cfi-bar-chart-line:before {
  content: "\e913";
}

.cfi-book-open:before {
  content: "\e914";
}

.cfi-code:before {
  content: "\e915";
}

.cfi-database:before {
  content: "\e916";
}

.cfi-dollar-sign:before {
  content: "\e917";
}

.cfi-folder-plus:before {
  content: "\e918";
}

.cfi-gift:before {
  content: "\e919";
}

.cfi-folder-minus:before {
  content: "\e91a";
}

.cfi-git-commit:before {
  content: "\e91b";
}

.cfi-git-branch:before {
  content: "\e91c";
}

.cfi-git-pull-request:before {
  content: "\e91d";
}

.cfi-git-merge:before {
  content: "\e91e";
}

.cfi-linkedin:before {
  content: "\e91f";
}

.cfi-hard-drive:before {
  content: "\e920";
}

.cfi-more-vertical-:before {
  content: "\e921";
}

.cfi-more-horizontal-:before {
  content: "\e922";
}

.cfi-rss:before {
  content: "\e923";
}

.cfi-send:before {
  content: "\e924";
}

.cfi-shield-off:before {
  content: "\e925";
}

.cfi-shopping-bag:before {
  content: "\e926";
}

.cfi-terminal:before {
  content: "\e927";
}

.cfi-truck:before {
  content: "\e928";
}

.cfi-zap-off:before {
  content: "\e929";
}

.cfi-youtube:before {
  content: "\e92a";
}