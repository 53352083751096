@import '../references/index';

* {
    .back-button {
        width: 45px;
        height: 45px;
        background-color: white;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 30px !important;
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar:hover {
        width: 10px;
        height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: #dddbdd;
        // box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color: #757575;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        width: 50px;
        background-color: #999999;
    }

    .vekalapp-skeleton {
        background-color: #e0e0e0;
        display: block;
        position: relative;
        overflow: hidden;
        background-color: #dddbdd;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
            );
            animation: skeleton-background-animation 5s infinite;
            content: '';
        }

        @keyframes skeleton-background-animation {
            100% {
                transform: translateX(100%);
            }
        }
    }
}

.un-styled-button,
.un-styled-button:hover,
.un-styled-button:active,
.un-styled-button:focus {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
}

.vapp-loading-element {
    display: inline-block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    margin: 64px auto 64px auto;

    &::after {
        content: ' ';
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #171c8f;
        border-color: #171c8f transparent #171c8f transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}
