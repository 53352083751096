@import 'src/utilities/references/index';

.vekalapp-user-profile-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include vekalapp-lg {
        margin-top: 48px;
        padding-right: 32px;
    }
    @include vekalapp-pre-lg {
        padding: 24px 22px 112px 22px;
    }

    .page-header {
        @include vekalapp-pre-lg {
            flex-direction: column;
            align-items: normal;
        }
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        > div {
            @include vekalapp-pre-lg {
                flex-direction: row;
                align-items: center;
                margin-bottom: 24px;
            }
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        .title {
            @include vekalapp-size(font-size, 24px, 22px, true);
            color: $text4;
            font-weight: 500;
            flex: 1;
        }
    }
    .user-profile-container {
        display: flex;
        flex-direction: column;
        @include vekalapp-lg {
            flex-direction: row;
        }
        align-items: center;
        background-color: #fff;
        @include vekalapp-size(padding, 20px, 16px);
        .personal-card {
            border-radius: 5px;
            @include vekalapp-lg {
                max-width: 200px;
            }
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background-color: #161734;
            padding: 18px 32px;
            .image-container {
                // max-width: 132px;
                max-height: 124px;
                // min-width: 132px;
                min-height: 124px;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                overflow: hidden;
                @include vekalapp-pre-lg {
                    max-width: 124px;
                }
                position: relative;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(359deg);
                    }
                }
                .loading {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 100;
                    background-color: rgba(0, 0, 0, 0.6);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > i {
                        font-size: 32px;
                        color: #fff;
                        animation: rotation 2s infinite linear;
                    }
                }
            }

            > button {
                @include vekalapp-pre-lg {
                    max-width: 124px;
                }
                width: 100%;
                cursor: pointer;
                height: 40px;
                outline: none;
                border-radius: 5px;
                border: solid 1px $accentBlue;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                margin: 16px 0;
                > p {
                    font-size: 14px;
                    font-weight: normal;
                    color: $accentBlue;
                    margin-right: 8px;
                }
                > i {
                    color: $accentBlue;
                    font-size: 16px;
                }
            }
            > h4 {
                font-size: 16px;
                font-weight: normal;
                color: $accentBlue;
                text-align: center;
            }
            > h6 {
                font-size: 14px;
                font-weight: normal;
                color: #fff;
                margin-top: 10px;
                text-align: center;
            }
        }
        .form-container {
            display: flex;
            flex-direction: column;
            flex: 1;
            @include vekalapp-lg {
                @include vekalapp-size(padding, 20px, 16px);
            }
            margin-right: 6px;
            @include vekalapp-pre-lg {
                width: 100%;
            }
            .my-row {
                width: 100%;
                display: flex;
                flex-direction: column;
                @include vekalapp-lg {
                    flex-direction: row;
                }
                align-items: center;
                margin-top: 26px;
                justify-content: center;
                @include vekalapp-lg {
                    &:nth-child(1) {
                        margin-top: 0 !important;
                    }
                }
                > * {
                    @include vekalapp-lg {
                        margin-right: 26px;
                    }
                    &:nth-child(1) {
                        margin-right: 0 !important;
                    }
                    &:nth-child(2) {
                        @include vekalapp-pre-lg {
                            margin-top: 26px;
                        }
                    }
                }
                > button {
                    @include vekalapp-lg {
                        max-width: 200px;
                    }
                }
            }
        }
    }
}
