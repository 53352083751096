@import '../../../../utilities/references/index';

.qa-container {
    .question-card-container {
        @include vekalapp-size(padding-top, 24px, 16px, true);
        @include vekalapp-size(padding-bottom, 24px, 16px, true);
        @include vekalapp-size(padding-right, 24px, 16px, true);
        @include vekalapp-size(padding-left, 24px, 16px, true);
        box-sizing: border-box;
        width: 100%;
        border-radius: 5px;
        background-color: #fff;
        margin-bottom: 26px;
        .title {
            font-size: 18px !important;
            color: $text4;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .text {
            font-size: 14px;
            color: $text3;
            margin-top: 18px;
            line-height: 2.18;
            overflow: hidden;
            position: relative;
            height: 86px;
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, white 100%);
                content: '';
                width: 200px;
                height: 32px;
            }
        }
        > div {
            @include vekalapp-pre-md {
                flex-direction: column;
                align-items: normal;
            }
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-top: 20px;
            > p {
                font-size: 12px;
                color: $primary;
                flex: 1;
            }
            > button {
                @include vekalapp-pre-md {
                    font-size: 14px;
                    width: 100%;
                    padding-top: 13px;
                    padding-bottom: 13px;
                    margin-top: 12px;
                }
                background-color: $accentLightBlue;
                padding: 0 24px;
                width: fit-content;
            }
        }
    }
}
