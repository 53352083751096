@import 'src/utilities/references/index';

.vekalapp-judicial-file-card {
    display: flex;
    flex-direction: column;
    padding: 26px 28px;
    background-color: #ffffff;
    border-radius: 5px;

    > .card-header {
        width: 100%;
        display: flex;
        border-bottom: $bg1 2px solid;
        padding-bottom: 16px;
        margin-bottom: 16px;

        > .profile {
            flex: 1;
            display: flex;
            > img {
                width: 45px;
                height: 45px;
                border-radius: 5px;
                margin-left: 12px;
            }
            > div {
                > h1 {
                    display: flex;
                    > span:first-child {
                        color: $text4;
                        font-size: 16px;
                        margin-left: 12px;
                    }
                    > span:nth-child(2) {
                        background-color: $success;
                        width: 22px;
                        height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px;
                        font-size: 12px;
                        color: white;
                    }
                }
                > p {
                    font-size: 12px;
                    color: $text3;
                }
            }
        }
    }

    .card-row {
        display: flex;
        width: 100%;
        @include vekalapp-pre-md {
            flex-direction: column;
        }

        .items {
            width: 100%;
            border-radius: 5px;
            background-color: $bg1;
            padding: 11px;
            @include vekalapp-pre-md {
                margin-bottom: 12px;
            }
            @include vekalapp-md {
                margin: 0 12px 12px 12px;
            }

            &.description {
                height: 75px;
                position: relative;
                overflow-y: hidden;
                &::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    bottom: 0;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, $bg1 100%);
                    width: 200px;
                    height: 40px;
                }
                &::before {
                    position: absolute;
                    content: '';
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 11px;
                    background-color: $bg1;
                }
            }

            .q-title {
                display: inline;
                font-size: 14px;
                font-weight: bold;
                color: $text3;
                margin-left: 6px;
            }

            .answer {
                display: inline;
                font-size: 14px;
                font-weight: normal;
                color: $text4;

                &.primary {
                    color: $primary;
                }
            }
        }
    }
}
