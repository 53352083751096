@import "../../assets/fonts/IRANSans/css/fontiran.css";

body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
div,
span,
button,
a,
p,
i,
ul,
li,
table,
tr,
td,
th,
tbody,
thead,
textarea {
    font-family: IRANSans !important;
    font-weight: 500;
}