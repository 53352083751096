@import 'src/utilities/references/index';

.vekalapp-user-panel-page {
    width: 100%;
    @include vekalapp-md {
        height: calc(100vh - 81px);
        overflow-y: auto;
    }
    > div {
        display: flex;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        @include vekalapp-md {
            padding: 0 32px;
        }
        margin: 0 auto;
        position: relative;

        > .side-bar-user-panel {
            top: 24px;
            right: 0;
            width: 100%;
            margin-top: 48px;
            height: calc(100vh - 80px - 64px);
            overflow-y: auto;
            padding: 22px;
            flex-direction: column;
            max-width: 244px;
            border-radius: 5px;
            row-gap: 30px;
            background-color: #4ec3e0;
            display: none;
            position: sticky;
            top: 48px;
            @include vekalapp-md {
                display: flex;
            }

            .items {
                display: flex;
                align-items: center;
                column-gap: 12px;
                cursor: pointer;
                height: 40px;
                padding: 0 8px;
                flex-shrink: 0;

                &.active {
                    background-color: #ffffff;
                    border-radius: 5px;

                    i,
                    p {
                        color: $primary;
                        font-size: 14px;
                    }
                }

                i,
                p {
                    font-weight: normal;
                    color: #ffffff;

                    &::selection {
                        display: none !important;
                    }
                }

                p {
                    font-size: 16px;
                }

                &:hover {
                    i,
                    p {
                        color: $primary;
                    }
                }
            }

            .user-detail {
                display: flex;
                column-gap: 12px;
                align-items: center;
                overflow: hidden;
                flex-shrink: 0;

                > img {
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                    object-fit: cover;
                }

                > button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    outline: none;
                    border: none;
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;

                    i {
                        font-weight: 300;
                    }
                }

                .detail {
                    color: #ffffff;

                    .name {
                        display: flex;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        font-weight: normal;
                    }

                    .role {
                        font-size: 12px;
                        font-weight: normal;
                    }
                }
            }
        }

        > .base-content {
            display: flex;
            flex: 1;
            overflow-x: hidden;
        }
    }
}
