@import 'src/utilities/references/index';

.vekalapp-mobile-profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 24px 136px 24px;
    background-color: white;
    min-height: 100vh;

    > button,
    img {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;
        width: 100px;
        flex-shrink: 0;
        height: 100px;
        border-radius: 20px;
        cursor: pointer;
        background-color: $bg1;
        object-fit: cover;

        i {
            font-size: 36px;
            color: $text4;
        }
    }
    > h2 {
        font-size: 18px;
        color: $primary;
        margin: 12px 0 6px;
    }

    > h3 {
        font-size: 12px;
        color: #161734;
    }

    .menu-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 24px;

        .items {
            display: flex;
            gap: 16px;
            color: #161734;

            > * {
                cursor: pointer;
            }

            > i {
                width: 52px;
                height: 52px;
                border-radius: 5px;
                background-color: $bg1;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.03);

                &:last-child {
                    border-radius: 50%;
                }
            }

            > p {
                flex: 1;
                display: flex;
                align-items: center;
                color: $text4;
            }
        }
    }
}
