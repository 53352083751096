@import '~bootstrap/scss/bootstrap-grid';
@import '~react-toastify/dist/ReactToastify.css';
@import './font';
@import './card';
@import './icons';
@import 'animations';

* {
    direction: rtl;
    text-align: right;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

body {
    padding: 0;
    margin: 0;
    background-color: $bg1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    outline: none;
    text-decoration: none;
}
.vekalapp-footer-content1 {
    display: none !important;
}
