@import '../../../utilities/references/index';

.question-detail-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include vekalapp-pre-md {
        padding: 24px 22px;
    }
    @include vekalapp-md {
        margin-right: 24px;
        margin-top: 48px;
    }

    .container-header {
        @include vekalapp-pre-md {
            flex-direction: row;
            align-items: center;
        }
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        .title {
            @include vekalapp-size(font-size, 24px, 22px, true);
            color: $text4;
            font-weight: 500;
            flex: 1;
        }
    }

    .question-detail {
        background-color: #ffffff;
        width: 100%;
        border-radius: 5px;
        padding: 26px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        .question-header {
            font-size: 16px;
            color: $text4;
            width: 100%;
            word-wrap: break-spaces;
            text-overflow: initial;
            white-space: initial;
            margin-bottom: 12px;
        }
        .question-description {
            font-size: 14px;
            color: $text3;
            @include vekalapp-size(margin-bottom, 20px, 14px, true);
        }
        .sim-questions {
            display: flex;
            flex-direction: column;
            > h4 {
                font-size: 14px;
                color: $text4;
            }
            .sim-question-linker {
                color: $primary;
                font-size: 12px;
                @include vekalapp-size(margin-top, 12px, 10px, true);
            }
        }

        .qa-view-counter {
            margin-top: 16px;
            font-size: 14px;
            color: $accentLightBlue;
            font-weight: normal;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: flex-end;
            > i {
                margin-left: 6px;
            }
        }
    }
    .qa-answer-divider {
        font-size: 18px;
        color: $text4;
        margin-bottom: 20px;
    }
}
