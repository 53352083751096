@import '../../../../utilities/references/index';

.answer-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    @include vekalapp-size(padding, 24px, 18px);
    .answer-card-body {
        border-top: solid 2px $bg1;
        padding: 20px 0 0;
        font-size: 16px;
        color: $text3;
    }
    .answer-card-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 18px;
        .lawyer-profile-button {
            width: 187px;
            height: 43px;
            border-radius: 5px;
            border: solid 0.5px $accentLightBlue;
            color: $accentLightBlue;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .lawyer-container {
            display: flex;
            flex-direction: row;
            flex: 1;
            align-items: center;
            > img {
                width: 56px;
                height: 56px;
                object-fit: cover;
                border-radius: 5px;
                margin-left: 14px;
            }
            > div {
                display: flex;
                flex-direction: column;

                > div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 8px;
                    > h4 {
                        font-size: 16px;
                        color: $text4;
                        margin-left: 16px;
                    }
                    > span {
                        width: 24px;
                        height: 24px;
                        background-color: $success;
                        border-radius: 5px;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        display: flex;
                        font-size: 12px;
                    }
                }
                > p {
                    font-size: 12px;
                    color: $text3;
                }
            }
        }
    }
}
