@keyframes vekalapp-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes vekalapp-fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes vekalapp-scale-up {
    0% {
        transform: scale(0.8) translateY(50px);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes vekalapp-scale-down {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(0.8) translateY(50px);
    }
}

@keyframes vekalapp-blur {
    0% {
        backdrop-filter: blur(0px) brightness(100%);
    }

    100% {
        backdrop-filter: blur(5px) brightness(80%);
    }
}

@keyframes vekalapp-un-blur {
    0% {
        backdrop-filter: blur(5px) brightness(80%);
    }

    100% {
        backdrop-filter: blur(0px) brightness(100%);
    }
}
