@import 'src/utilities/references/index';

.vekalapp-user-dashboard-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include vekalapp-md {
        margin-top: 48px;
        padding-right: 32px;
        padding-bottom: 14px;
    }
    @include vekalapp-pre-md {
        padding: 24px 22px 112px 22px;
    }
    //overflow-y: auto !important;
    //overflow-x: hidden;

    .page-header {
        @include vekalapp-pre-md {
            flex-direction: column;
            align-items: normal;
        }
        display: flex;
        margin-bottom: 36px;
        align-items: center;

        > div {
            @include vekalapp-pre-md {
                flex-direction: row;
                align-items: center;
                margin-bottom: 24px;
            }
            display: flex;
            flex-direction: column;
            flex: 1;

            > a > button {
                width: 45px;
            }

            > a {
                margin-left: 12px;
            }
        }

        > button {
            width: auto;
            flex-shrink: 0;
        }

        .title {
            @include vekalapp-size(font-size, 24px, 22px, true);
            color: $text4;
            font-weight: 500;
            flex: 1;
        }
    }

    .banner-info {
        display: flex;
        padding: 14px 18px;
        background-color: $text4;
        border-radius: 5px;
        margin-bottom: 36px;

        img {
            width: 56px;
            height: 56px;
            border-radius: 5px;
            object-fit: cover;
            background-color: $accentLightBlue;
            font-size: 12px;
            margin-left: 12px;

            &::selection {
                display: none !important;
            }
        }

        .items {
            display: flex;
            align-items: center;

            p {
                font-size: 15px;
                font-weight: normal;
            }

            .title {
                margin-left: 6px;
                color: #ffffff;
            }

            .val {
                color: $accentLightBlue;
            }
        }
    }

    .services {
        display: flex;
        flex-direction: column;

        p {
            color: $text4;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 16px;
        }

        .service-list {
            display: flex;
            justify-content: space-between;
            column-gap: 24px;

            .list-item {
                display: flex;
                width: 100%;
                flex-direction: column;
                max-width: 204px;
                border-radius: 5px;
                padding: 16px;
                background-color: #ffffff;

                .icon-div {
                    width: 79px;
                    height: 79px;
                    display: flex;
                    margin: 0 auto 12px auto;
                    border-radius: 5px;
                    justify-content: center;
                    align-items: center;
                    background-color: $bg1;

                    .vertical-revert {
                        transform: scaleX(-1);
                        transform: scaleY(-1);
                    }

                    &::selection {
                        display: none;
                    }

                    i {
                        color: $text4;
                        font-size: 24px;
                        font-weight: normal !important;

                        &::selection {
                            display: none;
                        }
                    }
                }

                .counts {
                    display: flex;
                    margin: 0 auto 4px auto;
                    color: $text4;
                    font-size: 18px;
                    font-weight: normal;
                }

                .title {
                    display: flex;
                    margin: 0 auto 18px auto;
                    color: $text4;
                    font-size: 16px;
                    font-weight: normal;
                }
            }
        }
    }

    .latest-messages-tickets {
        display: flex;
        width: 100%;
        margin-top: 36px;
        transition: 200ms;

        .messages {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex: 2;
            overflow: hidden;

            .title {
                font-size: 18px;
                font-weight: 500;
                color: $text4;
                margin-bottom: 16px;
            }
        }

        .tickets {
            width: 100%;
            display: flex;
            overflow: hidden;
            flex-direction: column;
            flex: 4;

            .header {
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 16px;

                .spacer {
                    display: flex;
                    flex: 1;
                    width: 100%;
                }

                .add-ticket {
                    display: flex;
                    font-size: 14px;

                    i {
                        margin-left: 6px;

                        &::selection {
                            display: none !important;
                        }
                    }
                }

                .title {
                    display: flex;
                    justify-content: flex-start;
                    font-size: 18px;
                    font-weight: 500;
                    color: $text4;
                }
            }
        }
    }
}
