@import 'src/utilities/references/index';

.vekalapp-judicial-file-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include vekalapp-md {
        margin-top: 48px;
        padding-right: 32px;
    }
    @include vekalapp-pre-md {
        padding: 24px 22px 112px 22px;
    }

    .page-header {
        @include vekalapp-pre-md {
            flex-direction: column;
            align-items: normal;
        }
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        > div {
            @include vekalapp-pre-md {
                flex-direction: row;
                align-items: center;
                margin-bottom: 24px;
            }
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        .title {
            @include vekalapp-size(font-size, 24px, 22px, true);
            color: $text4;
            font-weight: 500;
            flex: 1;
        }
    }
    .judicial-detail-content {
        background-color: white;
        border-radius: 4px;
        @include vekalapp-size(padding, 24px, 16px);
        .card-row {
            display: flex;
            margin: 0 -16px;

            .items {
                width: 100%;
                border-radius: 5px;
                background-color: $bg1;
                padding: 11px;
                margin: 0 12px 12px 12px;

                .q-title {
                    display: inline;
                    font-size: 14px;
                    font-weight: bold;
                    color: $text3;
                    margin-left: 6px;
                }

                .answer {
                    display: inline;
                    font-size: 14px;
                    font-weight: normal;
                    color: $text4;

                    &.primary {
                        color: $primary;
                    }
                }
            }
        }
    }
    .judicial-file-response {
        background-color: white;
        @include vekalapp-size(padding, 24px, 16px);
        border-radius: 4px;
        margin-top: 24px;

        > .card-header {
            width: 100%;
            display: flex;
            border-bottom: $bg1 2px solid;
            padding-bottom: 16px;
            margin-bottom: 16px;

            > .profile {
                flex: 1;
                display: flex;
                > img {
                    width: 45px;
                    height: 45px;
                    border-radius: 5px;
                    margin-left: 12px;
                }
                > div {
                    > h1 {
                        display: flex;
                        > span:first-child {
                            color: $text4;
                            font-size: 16px;
                            margin-left: 12px;
                        }
                        > span:nth-child(2) {
                            background-color: $success;
                            width: 22px;
                            height: 22px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 3px;
                            font-size: 12px;
                            color: white;
                        }
                    }
                    > p {
                        font-size: 12px;
                        color: $text3;
                    }
                }
            }
        }

        > .empty-state {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 24px 0;
            > img {
                width: 300px;
                height: auto;
                margin-bottom: 12px;
            }
            > p {
                font-size: 14px;
                color: $text3;
                text-align: center;
            }
        }
        > .lawyer-content {
            > label {
                font-size: 16px;
                color: $text4;
                margin-bottom: 12px;
                font-weight: normal;
                margin-bottom: 12px;
                display: block;
            }
            > div,
            > a {
                color: $text3;
                display: block;
                font-size: 14px;
                width: 100%;
                border-radius: 5px;
                background-color: $bg1;
                padding: 11px;
                margin-bottom: 24px;
            }
        }
    }
}
