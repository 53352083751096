@use 'sass:selector';
$primary: #171c8f;
$accentPurple: #8194dd;
$accentBlue: #00a3e0;
$accentLightBlue: #4ec3e0;
$bg1: #f1f2f5;
$bg2: #cacbd9;
$bg3: #9596b3;
$bg4: #626380;
$text1: #626380;
$text2: #7c7d9a;
$text3: #494a67;
$text4: #25275d;
$danger: #ff678c;
$yellowDark: #e4c100;
$success: #9ede59;
$info: #77ace9;
$grayLight: #d7dae2;
$warning: #ffe244;
$purple: #c34ee0;
$orange: #ff7700;
