@import "src/utilities/references/index";

.vekalapp-chat-message {
    display: flex;
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }

    .main-message {
        display: flex;
        flex-direction: row;
        width: 100%;
        column-gap: 12px;


        .img-div {
            display: flex;
            align-items: flex-end;

            img {
                border-radius: 100%;
                width: 40px;
                height: 40px;
            }
        }


        .message {
            width: fit-content;
            display: flex;
            flex-direction: column;
            background-color: $bg1;
            padding: 12px 16px;
            border-radius: 5px;

            &.darker-bg {
                background-color: #dadbe0;
            }

            .pm {
                color: $text4;
                font-size: 12px;
                font-weight: normal;
                word-break: break-word;
                max-width: 450px;
            }

            .time-attachment {
                display: flex;
                align-items: center;
                margin-top: 6px;
                overflow: hidden;
                column-gap: 6px;

                .f-name {
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: normal;
                    margin-left: 6px;
                }

                i {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    font-weight: normal;
                    color: $text4;
                    border-radius: 100%;
                    cursor: pointer;
                }

                .spacer {
                    display: flex;
                    flex: 1;
                    width: 100%;
                }

                .chat-time {
                    color: #8b8b9a;
                    width: 100%;
                    font-size: 10px;
                    font-weight: normal;
                }
            }
        }

        &.receiver {
            flex-direction: row-reverse;
        }
    }
}