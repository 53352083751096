@import 'src/utilities/references/index';

.vekalapp-telephone-advisory-card {
    display: flex;
    flex-direction: column;
    padding: 26px 28px;
    background-color: #ffffff;
    border-radius: 5px;

    > .card-header {
        width: 100%;
        display: flex;
        border-bottom: $bg1 2px solid;
        padding-bottom: 16px;
        margin-bottom: 16px;

        > .profile {
            flex: 1;
            display: flex;
            > img {
                width: 45px;
                height: 45px;
                border-radius: 5px;
                margin-left: 12px;
            }
            > div {
                > h1 {
                    display: flex;
                    > span:first-child {
                        color: $text4;
                        font-size: 16px;
                        margin-left: 12px;
                    }
                    > span:last-child {
                        background-color: $success;
                        width: 22px;
                        height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px;
                        font-size: 12px;
                        color: white;
                    }
                }
                > p {
                    font-size: 12px;
                    color: $text3;
                }
            }
        }
    }

    .card-row {
        display: flex;
        // margin: 0 -16px;
        width: 100%;
        box-sizing: border-box;
        @include vekalapp-pre-md {
            flex-direction: column;
        }

        .items {
            width: 100%;
            border-radius: 5px;
            background-color: $bg1;
            padding: 11px;
            box-sizing: border-box;
            align-items: center;
            display: flex;
            overflow: hidden;
            @include vekalapp-pre-md {
                margin-bottom: 12px;
            }
            @include vekalapp-md {
                margin: 0 12px 12px 12px;
            }

            > i {
                display: inline;
                font-size: 18px;
                color: $text3;
                margin-left: 6px;
                flex-shrink: 0;
            }

            .q-title {
                display: inline;
                font-size: 14px;
                font-weight: bold;
                color: $text3;
                margin-left: 6px;
                flex-shrink: 0;
            }

            .answer {
                display: inline;
                font-size: 14px;
                font-weight: normal;
                color: $text4;
                flex: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
