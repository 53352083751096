@import "src/utilities/references/index";

.dashboard-latest-messages {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: #fff;
    padding: 4px;
    height: 100%;
    width: 100%;

    .items {
        display: flex;
        align-items: center;
        padding: 10px;
        column-gap: 10px;
        cursor: pointer;
        width: 100%;

        &.active {
            background-color: $bg1;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom: 2px solid $accentLightBlue;
        }

        img {
            width: 45px;
            height: 45px;
            border-radius: 100%;

            &::selection {
                display: none !important;
            }
        }

        .chat-detail {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 100%;

            .brief-message {
                display: flex;
                overflow: hidden;
                align-items: center;

                .spacer {
                    display: flex;
                    flex: 1;
                    min-width: 10px;
                }

                .msg {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 11px;
                    font-weight: normal;
                    color: #8b8b9a;
                }

                .unread-messages-count {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    width: 15px;
                    height: 15px;
                    background-color: #fe668b;
                    font-size: 10px;
                    color: #ffffff;
                    font-weight: normal;
                }
            }

            .name-date {
                display: flex;
                align-items: center;

                .spacer {
                    flex: 1;
                    display: flex;
                    width: 100%;
                }

                .date-time {
                    font-size: 10px;
                    font-weight: normal;
                    color: #8b8b9a;
                }

                .name {
                    font-size: 14px;
                    font-weight: normal;
                    color: $text4;
                }
            }
        }
    }
}