@import "src/utilities/references/index";

.dashboard-latest-tickets {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 20px;
    height: fit-content;
    row-gap: 16px;

    .item {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 16px 18px;
        background: $bg1;
        border-radius: 5px;

        .header-ticket {
            display: flex;
            margin-bottom: 12px;

            .spacer {
                display: flex;
                flex: 1;
                min-width: 20px;
            }

            .title {
                color: $text4;
                font-size: 18px;
                font-weight: normal;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .date-status {
                font-size: 14px;
                font-weight: normal;
                color: #8b8b9a;
                flex-shrink: 0;
            }
        }

        .footer {
            display: flex;
            width: 100%;
            max-height: 56px;
            justify-content: space-between;
            align-items: flex-end;

            .desc {
                width: 100%;
                max-height: 56px;
                line-height: 1.5em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                height: 3em; /* height is 2x line-height, so two lines will display */

                p {
                    font-size: 16px;
                    font-weight: normal;
                    color: $text4;
                }

                span {
                    display: flex;
                    justify-content: flex-end;
                    text-decoration: none;
                    flex-shrink: 0 !important;
                    color: $primary;
                    margin-right: 6px;
                    cursor: pointer;
                }
            }
        }
    }
}