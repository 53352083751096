@import 'src/utilities/references/index';

.vekalapp-judicial-file-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include vekalapp-md {
        margin-top: 48px;
        padding-right: 32px;
    }
    @include vekalapp-pre-md {
        padding: 24px 22px 112px 22px;
    }

    .page-header {
        @include vekalapp-pre-md {
            flex-direction: column;
            align-items: normal;
        }
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        > div {
            @include vekalapp-pre-md {
                flex-direction: row;
                align-items: center;
                margin-bottom: 24px;
            }
            display: flex;
            flex-direction: column;
            flex: 1;
            > a > button {
                width: 45px;
            }
            > a {
                margin-left: 12px;
            }
        }
        > button {
            width: auto;
            flex-shrink: 0;
        }
        .title {
            @include vekalapp-size(font-size, 24px, 22px, true);
            color: $text4;
            font-weight: 500;
            flex: 1;
        }
    }

    .judicial-file-list-container {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }
    .empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 24px 0;
        > img {
            width: 300px;
            height: auto;
            margin-bottom: 12px;
        }
        > p {
            font-size: 14px;
            color: $text3;
            text-align: center;
        }
    }
}
