@import 'src/utilities/references/index';

.vekalapp-telephone-advisory-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include vekalapp-md {
        margin-top: 48px;
        padding-right: 32px;
    }
    @include vekalapp-pre-md {
        padding: 24px 22px 112px 22px;
    }

    .page-header {
        @include vekalapp-pre-md {
            flex-direction: column;
            align-items: normal;
        }
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        > div {
            @include vekalapp-pre-md {
                flex-direction: row;
                align-items: center;
                margin-bottom: 24px;
            }
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        .title {
            @include vekalapp-size(font-size, 24px, 22px, true);
            color: $text4;
            font-weight: 500;
            flex: 1;
        }
    }

    .telephone-advisory-data {
        display: flex;
        flex-direction: column;
        padding: 26px 28px;
        background-color: #ffffff;
        border-radius: 5px;

        > .card-header {
            width: 100%;
            display: flex;
            border-bottom: $bg1 2px solid;
            padding-bottom: 16px;
            margin-bottom: 16px;

            > .profile {
                flex: 1;
                display: flex;
                > img {
                    width: 45px;
                    height: 45px;
                    border-radius: 5px;
                    margin-left: 12px;
                }
                > div {
                    > h1 {
                        display: flex;
                        > span:first-child {
                            color: $text4;
                            font-size: 16px;
                            margin-left: 12px;
                        }
                        > span:last-child {
                            background-color: $success;
                            width: 22px;
                            height: 22px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 3px;
                            font-size: 12px;
                            color: white;
                        }
                    }
                    > p {
                        font-size: 12px;
                        color: $text3;
                    }
                }
            }
        }

        .card-row {
            display: flex;
            margin: 0 -16px;
            @include vekalapp-pre-md {
                flex-direction: column;
            }

            .items {
                width: 100%;
                border-radius: 5px;
                background-color: $bg1;
                padding: 11px;
                display: flex;
                align-items: center;
                @include vekalapp-pre-md {
                    margin-bottom: 12px;
                }
                @include vekalapp-md {
                    margin: 0 12px 12px 12px;
                }

                > i {
                    display: inline;
                    font-size: 18px;
                    color: $text3;
                    margin-left: 6px;
                    flex-shrink: 0;
                }

                .q-title {
                    display: inline;
                    font-size: 14px;
                    font-weight: bold;
                    color: $text3;
                    margin-left: 6px;
                }

                .answer {
                    display: inline;
                    font-size: 14px;
                    font-weight: normal;
                    color: $text4;
                    flex: 1;
                    &:not(.description) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }
        .end-advisory-q {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 18px;

            p {
                color: $primary;
                font-size: 14px;
                text-align: right;
                font-weight: normal;
                margin-bottom: 18px;
            }

            .q-btn {
                display: flex;
                column-gap: 20px;

                .confirm-reject {
                    font-weight: normal;
                    font-size: 14px;
                    height: 34px;

                    &:first-child {
                        max-width: 158px;
                    }

                    &:last-child {
                        max-width: 110px;
                    }
                }
            }
        }
    }
}
