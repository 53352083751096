@use 'sass:selector';
.vekalapp-text-input {
  position: relative;
  width: 100%; }
  .vekalapp-text-input .ti-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-text-input input {
    width: 100%;
    text-align: right;
    box-sizing: border-box;
    border: none !important;
    cursor: text;
    font-size: 14px;
    background-color: #f1f2f5;
    margin-top: 10px;
    height: 42px;
    font-weight: normal;
    padding: 10px 15px;
    border-radius: 4px;
    color: #25275d;
    outline: none; }
    .vekalapp-text-input input::placeholder {
      opacity: 1 !important;
      color: #b0b1c6 !important;
      font-size: 14px !important;
      font-weight: normal !important; }
    .vekalapp-text-input input:disabled {
      cursor: not-allowed; }
  .vekalapp-text-input.active .ti-label, .vekalapp-text-input.error .ti-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-text-input.active input::placeholder, .vekalapp-text-input.error input::placeholder {
    opacity: 1 !important;
    color: #b0b1c6 !important;
    font-size: 13px !important;
    font-weight: normal !important; }
  .vekalapp-text-input.error .input-error {
    margin: 0;
    color: #ff678c;
    text-align: right;
    font-size: 12px;
    font-weight: normal; }
  .vekalapp-text-input.error .index {
    border: solid 1px #ff678c !important; }
  .vekalapp-text-input.error input {
    border: 1px solid #ff678c !important; }

@use 'sass:selector';
.vekalapp-number-input {
  position: relative;
  width: 100%;
  /* Firefox */ }
  .vekalapp-number-input .ni-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-number-input input::-webkit-outer-spin-button,
  .vekalapp-number-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .vekalapp-number-input input[type=number] {
    -moz-appearance: textfield; }
  .vekalapp-number-input input {
    width: 100%;
    text-align: right;
    box-sizing: border-box;
    border: none !important;
    cursor: text;
    font-size: 14px;
    background-color: #f1f2f5;
    margin-top: 10px;
    height: 42px;
    font-weight: normal;
    padding: 10px 15px;
    border-radius: 4px;
    color: #25275d;
    outline: none; }
    .vekalapp-number-input input:disabled {
      cursor: not-allowed; }
    .vekalapp-number-input input::placeholder {
      opacity: 1 !important;
      color: #b0b1c6 !important;
      font-size: 14px !important;
      font-weight: normal !important; }
  .vekalapp-number-input.active .ti-label, .vekalapp-number-input.error .ti-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-number-input.active input::placeholder, .vekalapp-number-input.error input::placeholder {
    opacity: 1 !important;
    color: #b0b1c6 !important;
    font-size: 13px !important;
    font-weight: normal !important; }
  .vekalapp-number-input.error .input-error {
    margin: 0;
    color: #ff678c;
    text-align: right;
    font-size: 12px;
    font-weight: normal; }
  .vekalapp-number-input.error .index {
    border: solid 1px #ff678c !important; }
  .vekalapp-number-input.error input {
    border: 1px solid #ff678c !important; }

@use 'sass:selector';
.vekalapp-button {
  border-radius: 5px;
  color: #ffffff !important;
  width: 100%;
  background-color: #4ec3e0 !important;
  border: none;
  height: 42px;
  font-weight: normal;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .vekalapp-button.outlined-accentBlue {
    background-color: transparent !important;
    border: 1px solid #4ec3e0 !important;
    color: #4ec3e0 !important; }
  .vekalapp-button.navy {
    background-color: #171c8f !important; }
  .vekalapp-button:disabled {
    opacity: 0.6;
    cursor: not-allowed; }
    .vekalapp-button:disabled:focus {
      opacity: 0.6; }
    .vekalapp-button:disabled:hover {
      opacity: 0.6; }
  .vekalapp-button.small {
    height: 36px; }
  .vekalapp-button.medium {
    height: 42px; }
  .vekalapp-button.has-shadow {
    box-shadow: 0 18px 48px 0 rgba(78, 195, 224, 0.35); }
  .vekalapp-button.gradient {
    background: linear-gradient(98deg, #171c8f, #00a3e0);
    color: white !important; }
  .vekalapp-button.outlined {
    background-color: transparent !important;
    border: 1px solid #171c8f;
    color: #171c8f !important; }
  .vekalapp-button.filled {
    background-color: #171c8f !important;
    color: #f1f2f5 !important; }
  .vekalapp-button.loading {
    border: 1px solid #171c8f !important; }
  .vekalapp-button .lds-ellipsis {
    display: flex;
    position: relative;
    width: 80px;
    height: 80px; }
  .vekalapp-button .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #ffffff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0); }
  .vekalapp-button .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite; }
  .vekalapp-button .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite; }
  .vekalapp-button .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite; }
  .vekalapp-button .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }

@use 'sass:selector';
@media (min-width: 767.98px) {
  .vekalapp-single-dropdown-modal {
    display: none !important; } }

.vekalapp-single-dropdown-modal .modal-context-div {
  display: flex;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 767.98px) {
    .vekalapp-single-dropdown-modal .modal-context-div {
      display: none; } }
  .vekalapp-single-dropdown-modal .modal-context-div > * {
    width: 100%; }
  .vekalapp-single-dropdown-modal .modal-context-div input {
    height: 40px;
    direction: rtl;
    padding: 0 12px;
    width: 90%;
    margin: 10px auto;
    border: none;
    border-bottom: solid 1px #7c7d9a;
    outline: none;
    transition: border 0.3s;
    color: #25275d;
    font-size: 15px;
    font-weight: 400; }
    .vekalapp-single-dropdown-modal .modal-context-div input:hover {
      border-bottom: solid 1px #25275d; }
      .vekalapp-single-dropdown-modal .modal-context-div input:hover::placeholder {
        color: #25275d; }
  .vekalapp-single-dropdown-modal .modal-context-div .items-container .items {
    padding: 10px 12px;
    cursor: pointer;
    color: #494a67;
    font-size: 16px;
    font-weight: 400; }
    .vekalapp-single-dropdown-modal .modal-context-div .items-container .items:hover {
      background-color: #f1f2f5; }
  .vekalapp-single-dropdown-modal .modal-context-div .items-container .no-result {
    text-align: right;
    padding: 0 12px;
    color: #494a67;
    font-size: 14px;
    font-weight: 400; }

.vekalapp-single-dropdown {
  width: 100%;
  direction: rtl; }
  .vekalapp-single-dropdown .dd-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-single-dropdown .index {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    height: 42px;
    border-radius: 5px;
    border: none !important;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
    background-color: #f1f2f5;
    padding: 10px 15px;
    transition: color 0.3s; }
    .vekalapp-single-dropdown .index > * {
      transition: all 0.3s; }
    .vekalapp-single-dropdown .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important;
      font-weight: normal !important; }
    .vekalapp-single-dropdown .index .spacer {
      flex: 1; }
    .vekalapp-single-dropdown .index .placeholder {
      opacity: 1;
      font-size: 14px !important;
      color: #b0b1c6 !important;
      font-weight: normal !important; }
    .vekalapp-single-dropdown .index p {
      text-align: right;
      color: #25275d;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px; }
    .vekalapp-single-dropdown .index i {
      color: #25275d !important; }
  .vekalapp-single-dropdown.error .index {
    border: solid 1px #ff678c !important; }
  .vekalapp-single-dropdown.disable {
    cursor: not-allowed; }
    .vekalapp-single-dropdown.disable .index {
      border-color: #cacbd9;
      pointer-events: none; }
      .vekalapp-single-dropdown.disable .index > * {
        color: #cacbd9; }
  .vekalapp-single-dropdown .error-text {
    color: #ff678c;
    margin: 6px 4px 0 0;
    text-align: right;
    font-size: 13px;
    font-weight: normal; }

.single-dropdown-select-menu {
  display: none;
  z-index: 100000 !important;
  max-height: 0;
  width: fit-content;
  min-width: 300px;
  overflow: hidden;
  transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
  border: none !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  opacity: 0;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 20px 42px 20px rgba(22, 23, 52, 0.08); }
  @media (min-width: 767.98px) {
    .single-dropdown-select-menu {
      display: flex; } }
  .single-dropdown-select-menu.open {
    max-width: unset;
    max-height: 250px;
    overflow: auto;
    opacity: 1; }
  .single-dropdown-select-menu input {
    height: 40px;
    direction: rtl;
    padding: 10px 15px !important;
    width: 100% !important;
    margin: 0 auto;
    border: none;
    border-bottom: solid 1px #e4e4e4 !important;
    outline: none;
    transition: border 0.3s;
    color: #25275d;
    font-size: 13px !important;
    font-weight: 300; }
    .single-dropdown-select-menu input::placeholder {
      font-size: 13px;
      font-weight: 300; }
    .single-dropdown-select-menu input:hover {
      border-bottom: solid 1px #25275d; }
      .single-dropdown-select-menu input:hover::placeholder {
        color: #25275d; }
  .single-dropdown-select-menu .items-container {
    padding: 10px 15px;
    flex: 1;
    overflow: auto; }
    .single-dropdown-select-menu .items-container .items {
      padding: 10px 15px !important;
      cursor: pointer;
      color: #25275d;
      font-size: 13px !important;
      font-weight: 300;
      text-align: right; }
      .single-dropdown-select-menu .items-container .items:hover {
        background-color: #f1f2f5; }
    .single-dropdown-select-menu .items-container .no-result {
      text-align: right;
      padding: 0 12px;
      color: #494a67;
      font-size: 14px;
      font-weight: 400; }

@use 'sass:selector';
@keyframes vekalapp-popup-fade-in {
  0% {
    background-color: rgba(215, 218, 226, 0); }
  100% {
    background-color: rgba(215, 218, 226, 0.64); } }

@keyframes vekalapp-popup-fade-out {
  0% {
    background-color: rgba(215, 218, 226, 0.64); }
  100% {
    background-color: rgba(215, 218, 226, 0); } }

@keyframes vekalapp-popup-drawer-up {
  0% {
    padding-top: 100vh; }
  100% {
    padding-top: 30vh; } }

@keyframes vekalapp-popup-drawer-down {
  0% {
    padding-top: 30vh; }
  100% {
    padding-top: 100vh; } }

@keyframes vekalapp-popup-scale-up {
  0% {
    transform: scale(0.8) translate(0, 200px);
    opacity: 0; }
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 1; } }

@keyframes vekalapp-popup-scale-down {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale(0.8) translate(0, 200px); } }

.vekalapp-popup > .popup-container > .popup-content {
  padding: 30px; }

.vekalapp-popup.popup-bottom-sheet {
  z-index: 100000;
  background-color: rgba(215, 218, 226, 0.64);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: auto;
  scroll-behavior: smooth; }
  .vekalapp-popup.popup-bottom-sheet.close {
    display: none !important; }
  .vekalapp-popup.popup-bottom-sheet .popup-header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px; }
    .vekalapp-popup.popup-bottom-sheet .popup-header.no-title {
      display: flex;
      flex: 1;
      justify-content: flex-end; }
    .vekalapp-popup.popup-bottom-sheet .popup-header p {
      color: #25275d;
      font-size: 22px;
      font-weight: 500; }
    .vekalapp-popup.popup-bottom-sheet .popup-header .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
      background-color: #fe668b;
      width: 26px;
      outline: none;
      border: none;
      height: 26px;
      color: #fff;
      font-size: 20px;
      border-radius: 5px;
      cursor: pointer; }
  .vekalapp-popup.popup-bottom-sheet::-webkit-scrollbar {
    display: none !important; }
  .vekalapp-popup.popup-bottom-sheet.opening {
    animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
    .vekalapp-popup.popup-bottom-sheet.opening > .popup-container {
      animation: vekalapp-popup-drawer-up 0.3s ease-in-out; }
  .vekalapp-popup.popup-bottom-sheet.closing {
    animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
    .vekalapp-popup.popup-bottom-sheet.closing > .popup-container {
      animation: vekalapp-popup-drawer-down 0.3s ease-in-out; }
  .vekalapp-popup.popup-bottom-sheet > .popup-container {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 30vh;
    box-sizing: border-box; }
    .vekalapp-popup.popup-bottom-sheet > .popup-container > .popup-content {
      background-color: white;
      width: 100%;
      border-radius: 12px 12px 0 0; }
      .vekalapp-popup.popup-bottom-sheet > .popup-container > .popup-content > button.close-button {
        display: none; }

@media (min-width: 767.98px) {
  .vekalapp-popup.popup-md-bottom-sheet {
    z-index: 100000;
    background-color: rgba(215, 218, 226, 0.64);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
    scroll-behavior: smooth; }
    .vekalapp-popup.popup-md-bottom-sheet.close {
      display: none !important; }
    .vekalapp-popup.popup-md-bottom-sheet .popup-header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px; }
      .vekalapp-popup.popup-md-bottom-sheet .popup-header.no-title {
        display: flex;
        flex: 1;
        justify-content: flex-end; }
      .vekalapp-popup.popup-md-bottom-sheet .popup-header p {
        color: #25275d;
        font-size: 22px;
        font-weight: 500; }
      .vekalapp-popup.popup-md-bottom-sheet .popup-header .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
        background-color: #fe668b;
        width: 26px;
        outline: none;
        border: none;
        height: 26px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        cursor: pointer; }
    .vekalapp-popup.popup-md-bottom-sheet::-webkit-scrollbar {
      display: none !important; }
    .vekalapp-popup.popup-md-bottom-sheet.opening {
      animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
      .vekalapp-popup.popup-md-bottom-sheet.opening > .popup-container {
        animation: vekalapp-popup-drawer-up 0.3s ease-in-out; }
    .vekalapp-popup.popup-md-bottom-sheet.closing {
      animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
      .vekalapp-popup.popup-md-bottom-sheet.closing > .popup-container {
        animation: vekalapp-popup-drawer-down 0.3s ease-in-out; }
    .vekalapp-popup.popup-md-bottom-sheet > .popup-container {
      width: 100%;
      min-height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      padding-top: 30vh;
      box-sizing: border-box; }
      .vekalapp-popup.popup-md-bottom-sheet > .popup-container > .popup-content {
        background-color: white;
        width: 100%;
        border-radius: 12px 12px 0 0; }
        .vekalapp-popup.popup-md-bottom-sheet > .popup-container > .popup-content > button.close-button {
          display: none; } }

@media (max-width: 767.98px) {
  .vekalapp-popup.popup-pre-md-bottom-sheet {
    z-index: 100000;
    background-color: rgba(215, 218, 226, 0.64);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
    scroll-behavior: smooth; }
    .vekalapp-popup.popup-pre-md-bottom-sheet.close {
      display: none !important; }
    .vekalapp-popup.popup-pre-md-bottom-sheet .popup-header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px; }
      .vekalapp-popup.popup-pre-md-bottom-sheet .popup-header.no-title {
        display: flex;
        flex: 1;
        justify-content: flex-end; }
      .vekalapp-popup.popup-pre-md-bottom-sheet .popup-header p {
        color: #25275d;
        font-size: 22px;
        font-weight: 500; }
      .vekalapp-popup.popup-pre-md-bottom-sheet .popup-header .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
        background-color: #fe668b;
        width: 26px;
        outline: none;
        border: none;
        height: 26px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        cursor: pointer; }
    .vekalapp-popup.popup-pre-md-bottom-sheet::-webkit-scrollbar {
      display: none !important; }
    .vekalapp-popup.popup-pre-md-bottom-sheet.opening {
      animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
      .vekalapp-popup.popup-pre-md-bottom-sheet.opening > .popup-container {
        animation: vekalapp-popup-drawer-up 0.3s ease-in-out; }
    .vekalapp-popup.popup-pre-md-bottom-sheet.closing {
      animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
      .vekalapp-popup.popup-pre-md-bottom-sheet.closing > .popup-container {
        animation: vekalapp-popup-drawer-down 0.3s ease-in-out; }
    .vekalapp-popup.popup-pre-md-bottom-sheet > .popup-container {
      width: 100%;
      min-height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      padding-top: 30vh;
      box-sizing: border-box; }
      .vekalapp-popup.popup-pre-md-bottom-sheet > .popup-container > .popup-content {
        background-color: white;
        width: 100%;
        border-radius: 12px 12px 0 0; }
        .vekalapp-popup.popup-pre-md-bottom-sheet > .popup-container > .popup-content > button.close-button {
          display: none; } }

.vekalapp-popup.popup-modal {
  z-index: 100000;
  background-color: rgba(215, 218, 226, 0.64);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center; }
  .vekalapp-popup.popup-modal.close {
    display: none !important; }
  .vekalapp-popup.popup-modal .popup-header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px; }
    .vekalapp-popup.popup-modal .popup-header.no-title {
      display: flex;
      flex: 1;
      justify-content: flex-end; }
    .vekalapp-popup.popup-modal .popup-header p {
      color: #25275d;
      font-size: 22px;
      font-weight: 500; }
    .vekalapp-popup.popup-modal .popup-header .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
      background-color: #fe668b;
      width: 26px;
      outline: none;
      border: none;
      height: 26px;
      color: #fff;
      font-size: 20px;
      border-radius: 5px;
      cursor: pointer; }
  .vekalapp-popup.popup-modal.opening {
    animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
    .vekalapp-popup.popup-modal.opening > .popup-container > .popup-content {
      animation: vekalapp-popup-scale-up 0.3s ease-in-out; }
  .vekalapp-popup.popup-modal.closing {
    animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
    .vekalapp-popup.popup-modal.closing > .popup-container > .popup-content {
      animation: vekalapp-popup-scale-down 0.3s ease-in-out; }
  .vekalapp-popup.popup-modal > .popup-container {
    width: 100%;
    max-height: 100vh;
    position: relative;
    padding: 64px 24px;
    display: flex;
    justify-content: center;
    box-sizing: border-box; }
    .vekalapp-popup.popup-modal > .popup-container > .popup-content {
      background-color: white;
      width: 100%;
      max-width: 800px;
      border-radius: 6px;
      position: relative;
      overflow: auto;
      margin: auto; }
      .vekalapp-popup.popup-modal > .popup-container > .popup-content > button.close-button {
        background-color: white;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #78909c;
        border: none;
        position: absolute;
        right: 0;
        top: -48px; }

@media (min-width: 767.98px) {
  .vekalapp-popup.popup-md-modal {
    z-index: 100000;
    background-color: rgba(215, 218, 226, 0.64);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center; }
    .vekalapp-popup.popup-md-modal.close {
      display: none !important; }
    .vekalapp-popup.popup-md-modal .popup-header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px; }
      .vekalapp-popup.popup-md-modal .popup-header.no-title {
        display: flex;
        flex: 1;
        justify-content: flex-end; }
      .vekalapp-popup.popup-md-modal .popup-header p {
        color: #25275d;
        font-size: 22px;
        font-weight: 500; }
      .vekalapp-popup.popup-md-modal .popup-header .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
        background-color: #fe668b;
        width: 26px;
        outline: none;
        border: none;
        height: 26px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        cursor: pointer; }
    .vekalapp-popup.popup-md-modal.opening {
      animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
      .vekalapp-popup.popup-md-modal.opening > .popup-container > .popup-content {
        animation: vekalapp-popup-scale-up 0.3s ease-in-out; }
    .vekalapp-popup.popup-md-modal.closing {
      animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
      .vekalapp-popup.popup-md-modal.closing > .popup-container > .popup-content {
        animation: vekalapp-popup-scale-down 0.3s ease-in-out; }
    .vekalapp-popup.popup-md-modal > .popup-container {
      width: 100%;
      max-height: 100vh;
      position: relative;
      padding: 64px 24px;
      display: flex;
      justify-content: center;
      box-sizing: border-box; }
      .vekalapp-popup.popup-md-modal > .popup-container > .popup-content {
        background-color: white;
        width: 100%;
        max-width: 800px;
        border-radius: 6px;
        position: relative;
        overflow: auto;
        margin: auto; }
        .vekalapp-popup.popup-md-modal > .popup-container > .popup-content > button.close-button {
          background-color: white;
          width: 32px;
          height: 32px;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #78909c;
          border: none;
          position: absolute;
          right: 0;
          top: -48px; } }

@media (max-width: 767.98px) {
  .vekalapp-popup.popup-pre-md-modal {
    z-index: 100000;
    background-color: rgba(215, 218, 226, 0.64);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center; }
    .vekalapp-popup.popup-pre-md-modal.close {
      display: none !important; }
    .vekalapp-popup.popup-pre-md-modal .popup-header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px; }
      .vekalapp-popup.popup-pre-md-modal .popup-header.no-title {
        display: flex;
        flex: 1;
        justify-content: flex-end; }
      .vekalapp-popup.popup-pre-md-modal .popup-header p {
        color: #25275d;
        font-size: 22px;
        font-weight: 500; }
      .vekalapp-popup.popup-pre-md-modal .popup-header .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
        background-color: #fe668b;
        width: 26px;
        outline: none;
        border: none;
        height: 26px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        cursor: pointer; }
    .vekalapp-popup.popup-pre-md-modal.opening {
      animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
      .vekalapp-popup.popup-pre-md-modal.opening > .popup-container > .popup-content {
        animation: vekalapp-popup-scale-up 0.3s ease-in-out; }
    .vekalapp-popup.popup-pre-md-modal.closing {
      animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
      .vekalapp-popup.popup-pre-md-modal.closing > .popup-container > .popup-content {
        animation: vekalapp-popup-scale-down 0.3s ease-in-out; }
    .vekalapp-popup.popup-pre-md-modal > .popup-container {
      width: 100%;
      max-height: 100vh;
      position: relative;
      padding: 64px 24px;
      display: flex;
      justify-content: center;
      box-sizing: border-box; }
      .vekalapp-popup.popup-pre-md-modal > .popup-container > .popup-content {
        background-color: white;
        width: 100%;
        max-width: 800px;
        border-radius: 6px;
        position: relative;
        overflow: auto;
        margin: auto; }
        .vekalapp-popup.popup-pre-md-modal > .popup-container > .popup-content > button.close-button {
          background-color: white;
          width: 32px;
          height: 32px;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #78909c;
          border: none;
          position: absolute;
          right: 0;
          top: -48px; } }

.vekalapp-popup.popup-page {
  z-index: 100000;
  background-color: rgba(215, 218, 226, 0.64);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center; }
  .vekalapp-popup.popup-page.close {
    display: none !important; }
  .vekalapp-popup.popup-page .popup-header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px; }
    .vekalapp-popup.popup-page .popup-header.no-title {
      display: flex;
      flex: 1;
      justify-content: flex-end; }
    .vekalapp-popup.popup-page .popup-header p {
      color: #25275d;
      font-size: 22px;
      font-weight: 500; }
    .vekalapp-popup.popup-page .popup-header .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
      background-color: #fe668b;
      width: 26px;
      outline: none;
      border: none;
      height: 26px;
      color: #fff;
      font-size: 20px;
      border-radius: 5px;
      cursor: pointer; }
  .vekalapp-popup.popup-page.opening {
    animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
    .vekalapp-popup.popup-page.opening > .popup-container > .popup-content {
      animation: vekalapp-popup-scale-up 0.3s ease-in-out; }
  .vekalapp-popup.popup-page.closing {
    animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
    .vekalapp-popup.popup-page.closing > .popup-container > .popup-content {
      animation: vekalapp-popup-scale-down 0.3s ease-in-out; }
  .vekalapp-popup.popup-page > .popup-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow-y: auto;
    box-sizing: border-box; }
    .vekalapp-popup.popup-page > .popup-container > .popup-content {
      background-color: white;
      width: 100%;
      min-height: 100vh;
      position: relative; }

@media (min-width: 767.98px) {
  .vekalapp-popup.popup-md-page {
    z-index: 100000;
    background-color: rgba(215, 218, 226, 0.64);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center; }
    .vekalapp-popup.popup-md-page.close {
      display: none !important; }
    .vekalapp-popup.popup-md-page .popup-header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px; }
      .vekalapp-popup.popup-md-page .popup-header.no-title {
        display: flex;
        flex: 1;
        justify-content: flex-end; }
      .vekalapp-popup.popup-md-page .popup-header p {
        color: #25275d;
        font-size: 22px;
        font-weight: 500; }
      .vekalapp-popup.popup-md-page .popup-header .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
        background-color: #fe668b;
        width: 26px;
        outline: none;
        border: none;
        height: 26px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        cursor: pointer; }
    .vekalapp-popup.popup-md-page.opening {
      animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
      .vekalapp-popup.popup-md-page.opening > .popup-container > .popup-content {
        animation: vekalapp-popup-scale-up 0.3s ease-in-out; }
    .vekalapp-popup.popup-md-page.closing {
      animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
      .vekalapp-popup.popup-md-page.closing > .popup-container > .popup-content {
        animation: vekalapp-popup-scale-down 0.3s ease-in-out; }
    .vekalapp-popup.popup-md-page > .popup-container {
      width: 100vw;
      height: 100vh;
      position: relative;
      overflow-y: auto;
      box-sizing: border-box; }
      .vekalapp-popup.popup-md-page > .popup-container > .popup-content {
        background-color: white;
        width: 100%;
        min-height: 100vh;
        position: relative; } }

@media (max-width: 767.98px) {
  .vekalapp-popup.popup-pre-md-page {
    z-index: 100000;
    background-color: rgba(215, 218, 226, 0.64);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center; }
    .vekalapp-popup.popup-pre-md-page.close {
      display: none !important; }
    .vekalapp-popup.popup-pre-md-page .popup-header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px; }
      .vekalapp-popup.popup-pre-md-page .popup-header.no-title {
        display: flex;
        flex: 1;
        justify-content: flex-end; }
      .vekalapp-popup.popup-pre-md-page .popup-header p {
        color: #25275d;
        font-size: 22px;
        font-weight: 500; }
      .vekalapp-popup.popup-pre-md-page .popup-header .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 9px 12px 0 rgba(254, 102, 139, 0.35);
        background-color: #fe668b;
        width: 26px;
        outline: none;
        border: none;
        height: 26px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        cursor: pointer; }
    .vekalapp-popup.popup-pre-md-page.opening {
      animation: vekalapp-popup-fade-in 0.3s ease-in-out; }
      .vekalapp-popup.popup-pre-md-page.opening > .popup-container > .popup-content {
        animation: vekalapp-popup-scale-up 0.3s ease-in-out; }
    .vekalapp-popup.popup-pre-md-page.closing {
      animation: vekalapp-popup-fade-out 0.3s ease-in-out; }
      .vekalapp-popup.popup-pre-md-page.closing > .popup-container > .popup-content {
        animation: vekalapp-popup-scale-down 0.3s ease-in-out; }
    .vekalapp-popup.popup-pre-md-page > .popup-container {
      width: 100vw;
      height: 100vh;
      position: relative;
      overflow-y: auto;
      box-sizing: border-box; }
      .vekalapp-popup.popup-pre-md-page > .popup-container > .popup-content {
        background-color: white;
        width: 100%;
        min-height: 100vh;
        position: relative; } }

@use 'sass:selector';
@media (min-width: 767.98px) {
  .vekalapp-multi-dropdown-modal {
    display: none !important; } }

.vekalapp-multi-dropdown-modal .modal-context-div {
  display: flex;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 767.98px) {
    .vekalapp-multi-dropdown-modal .modal-context-div {
      display: none; } }
  .vekalapp-multi-dropdown-modal .modal-context-div > * {
    width: 100%; }
  .vekalapp-multi-dropdown-modal .modal-context-div input {
    height: 40px;
    direction: rtl;
    padding: 0 12px;
    width: 90%;
    margin: 10px auto;
    border: none;
    border-bottom: solid 1px #7c7d9a;
    outline: none;
    transition: border 0.3s;
    color: #25275d;
    font-size: 15px;
    font-weight: 400; }
    .vekalapp-multi-dropdown-modal .modal-context-div input:hover {
      border-bottom: solid 1px #25275d; }
      .vekalapp-multi-dropdown-modal .modal-context-div input:hover::placeholder {
        color: #25275d; }
  .vekalapp-multi-dropdown-modal .modal-context-div .items-container .items {
    padding: 10px 12px;
    cursor: pointer;
    color: #494a67;
    font-size: 16px;
    font-weight: 400; }
    .vekalapp-multi-dropdown-modal .modal-context-div .items-container .items:hover {
      background-color: #f1f2f5; }
  .vekalapp-multi-dropdown-modal .modal-context-div .items-container .no-result {
    text-align: right;
    padding: 0 12px;
    color: #494a67;
    font-size: 14px;
    font-weight: 400; }

.vekalapp-multi-dropdown {
  width: 100%;
  background-color: white;
  direction: rtl; }
  .vekalapp-multi-dropdown .dd-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-weight: normal !important;
    cursor: pointer;
    background-color: transparent !important; }
  .vekalapp-multi-dropdown .index {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 15px;
    min-height: 42px;
    transition: color 0.3s; }
    .vekalapp-multi-dropdown .index > * {
      transition: all 0.3s; }
    .vekalapp-multi-dropdown .index .spacer {
      flex: 1; }
    .vekalapp-multi-dropdown .index .placeholder {
      opacity: 1;
      font-size: 14px !important;
      color: #b0b1c6 !important;
      font-weight: normal !important; }
    .vekalapp-multi-dropdown .index .items-container {
      min-height: 34px;
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      flex-direction: row;
      cursor: default; }
    .vekalapp-multi-dropdown .index .items {
      display: flex;
      align-items: center;
      flex-direction: row;
      background-color: #f1f2f5;
      border-radius: 5px;
      padding: 4px;
      margin: 0 0 0 8px; }
      .vekalapp-multi-dropdown .index .items p {
        text-align: right;
        color: #25275d;
        margin: 0;
        padding: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-weight: normal; }
      .vekalapp-multi-dropdown .index .items i {
        cursor: pointer;
        margin-right: 2px;
        color: #7c7d9a;
        padding: 3px;
        border-radius: 100%;
        font-size: 15px;
        transition: background-color 0.3s, color 0.3s; }
        .vekalapp-multi-dropdown .index .items i:hover {
          color: #25275d;
          background-color: rgba(202, 203, 217, 0.3); }
    .vekalapp-multi-dropdown .index i {
      color: #494a67; }
  .vekalapp-multi-dropdown .vekalapp-modal {
    display: flex; }
    @media (min-width: 767.98px) {
      .vekalapp-multi-dropdown .vekalapp-modal {
        display: none; } }
  .vekalapp-multi-dropdown.error .index {
    border: solid 2px #ff678c; }
  .vekalapp-multi-dropdown.disable {
    cursor: not-allowed; }
    .vekalapp-multi-dropdown.disable .index {
      border-color: #cacbd9;
      pointer-events: none; }
      .vekalapp-multi-dropdown.disable .index > * {
        color: #cacbd9; }
  .vekalapp-multi-dropdown .error-text {
    color: #ff678c;
    margin: 6px 4px 0 0;
    text-align: right;
    font-size: 13px; }

.multi-dropdown-select-menu {
  display: none;
  z-index: 100000 !important;
  max-height: 0;
  width: fit-content;
  min-width: 300px;
  overflow: hidden;
  transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
  border: none !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  opacity: 0;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 20px 42px 20px rgba(22, 23, 52, 0.08); }
  @media (min-width: 767.98px) {
    .multi-dropdown-select-menu {
      display: flex; } }
  .multi-dropdown-select-menu.open {
    max-width: unset;
    max-height: 250px;
    overflow: auto;
    opacity: 1; }
  .multi-dropdown-select-menu input {
    height: 40px;
    direction: rtl;
    padding: 10px 15px !important;
    width: 100% !important;
    margin: 0 auto;
    border: none;
    border-bottom: solid 1px #e4e4e4 !important;
    outline: none;
    transition: border 0.3s;
    color: #25275d;
    font-size: 13px !important;
    font-weight: 300; }
    .multi-dropdown-select-menu input::placeholder {
      font-size: 13px;
      font-weight: 300; }
    .multi-dropdown-select-menu input:hover {
      border-bottom: solid 1px #25275d; }
      .multi-dropdown-select-menu input:hover::placeholder {
        color: #25275d; }
  .multi-dropdown-select-menu .items-container {
    padding: 10px 15px;
    flex: 1;
    overflow: auto; }
    .multi-dropdown-select-menu .items-container .items {
      padding: 10px 15px !important;
      cursor: pointer;
      color: #25275d;
      font-size: 13px !important;
      font-weight: 300;
      text-align: right; }
      .multi-dropdown-select-menu .items-container .items:hover {
        background-color: #f1f2f5; }
    .multi-dropdown-select-menu .items-container .no-result {
      text-align: right;
      padding: 0 12px;
      color: #494a67;
      font-size: 14px;
      font-weight: 400; }

/* Default state */
.rc-checkbox {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: middle;
}
.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #3dbcf6;
}
.rc-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: #d9d9d9;
  background-color: #ffffff;
  transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.rc-checkbox-inner:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-duration: 0.3s;
  animation-name: amCheckboxOut;
}
.rc-checkbox-input {
  position: absolute;
  left: 0;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
/* Checked state */
.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #3dbcf6;
}
.rc-checkbox-checked .rc-checkbox-inner {
  border-color: #3dbcf6;
  background-color: #3dbcf6;
}
.rc-checkbox-checked .rc-checkbox-inner:after {
  transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 1px;
  display: table;
  width: 5px;
  height: 8px;
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
  content: ' ';
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-duration: 0.3s;
  animation-name: amCheckboxOut;
}
@media print {
  .rc-checkbox-checked .rc-checkbox-inner {
    box-shadow: inset 0 0 0 16px #3dbcf6;
  }
}
.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #d9d9d9;
}
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #f3f3f3;
  border-color: #d9d9d9;
}
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
  animation-name: none;
  border-color: #cccccc;
}
@media print {
  .rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
    box-shadow: inset 0 0 0 16px #f3f3f3;
  }
}
.rc-checkbox-disabled:hover .rc-checkbox-inner {
  border-color: #d9d9d9;
}
.rc-checkbox-disabled .rc-checkbox-inner {
  border-color: #d9d9d9;
  background-color: #f3f3f3;
}
.rc-checkbox-disabled .rc-checkbox-inner:after {
  animation-name: none;
  border-color: #f3f3f3;
}
.rc-checkbox-disabled .rc-checkbox-inner-input {
  cursor: default;
}
@keyframes amCheckboxIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1) rotate(45deg);
  }
}
@keyframes amCheckboxOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@use 'sass:selector';
.vekalapp-pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px; }
  .vekalapp-pagination > div {
    background-color: white;
    padding: 6px 24px;
    border-radius: 4px;
    box-shadow: 0 20px 42px 0 rgba(22, 23, 52, 0.08); }
    .vekalapp-pagination > div i {
      cursor: pointer;
      color: #171c8f;
      display: flex;
      align-items: center;
      font-size: 20px;
      margin: 0 5px 0 5px;
      text-decoration: #9ede59; }
      .vekalapp-pagination > div i.disabled {
        opacity: 0.5;
        cursor: not-allowed; }
      .vekalapp-pagination > div i i.byte-ex-1::selection {
        background: red; }
    .vekalapp-pagination > div .page-numbers {
      display: flex;
      border-radius: 4px; }
      .vekalapp-pagination > div .page-numbers span {
        border-radius: 4px; }
      .vekalapp-pagination > div .page-numbers .nums {
        background-color: #ffffff;
        width: 32px;
        height: 32px;
        display: flex;
        color: #171c8f;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: normal;
        margin: 0 6px; }
        .vekalapp-pagination > div .page-numbers .nums.active {
          background-color: #171c8f;
          color: #ffffff; }

@use 'sass:selector';
.vekalapp-icon-button {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: none;
  font-weight: normal !important;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }
  .vekalapp-icon-button.blue {
    background-color: rgba(119, 172, 233, 0.25);
    color: #77ace9; }
    .vekalapp-icon-button.blue .lds-dual-ring:after {
      border: 3px solid #77ace9;
      border-color: #77ace9 transparent #77ace9 transparent; }
  .vekalapp-icon-button.green {
    background-color: rgba(158, 222, 89, 0.25);
    color: #9ede59; }
    .vekalapp-icon-button.green .lds-dual-ring:after {
      border: 3px solid #9ede59;
      border-color: #9ede59 transparent #9ede59 transparent; }
  .vekalapp-icon-button.red {
    background-color: rgba(255, 103, 140, 0.25);
    color: #ff678c; }
    .vekalapp-icon-button.red .lds-dual-ring:after {
      border: 3px solid #ff678c;
      border-color: #ff678c transparent #ff678c transparent; }
  .vekalapp-icon-button.yellow {
    background-color: rgba(228, 193, 0, 0.25);
    color: #e4c100; }
    .vekalapp-icon-button.yellow .lds-dual-ring:after {
      border: 3px solid #e4c100;
      border-color: #e4c100 transparent #e4c100 transparent; }
  .vekalapp-icon-button:disabled {
    background-color: gray;
    cursor: not-allowed; }
  .vekalapp-icon-button .lds-dual-ring {
    display: inline-block;
    width: 43px;
    height: 43px; }
  .vekalapp-icon-button .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@use 'sass:selector';
.vekalapp-modal {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  animation: vekalapp-blur 0.3s 1 forwards; }
  .vekalapp-modal .vekalapp-modal-container-div {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%; }
    .vekalapp-modal .vekalapp-modal-container-div .vekalapp-modal-container {
      max-width: 800px !important;
      height: fit-content;
      width: 100%;
      background-color: white;
      max-height: calc(100vh - 48px);
      border-radius: 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      animation: vekalapp-scale-up 0.3s 1 forwards cubic-bezier(0.33, 0.72, 0.67, 1.44);
      padding: 24px;
      margin: 16px; }
      .vekalapp-modal .vekalapp-modal-container-div .vekalapp-modal-container .modal-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px; }
        .vekalapp-modal .vekalapp-modal-container-div .vekalapp-modal-container .modal-header h3 {
          color: #171c8f;
          font-weight: bold;
          font-size: 16px; }
      .vekalapp-modal .vekalapp-modal-container-div .vekalapp-modal-container .modal-footer {
        display: flex;
        margin: -18px;
        padding-top: 30px; }
        .vekalapp-modal .vekalapp-modal-container-div .vekalapp-modal-container .modal-footer > button {
          margin: 18px; }
          .vekalapp-modal .vekalapp-modal-container-div .vekalapp-modal-container .modal-footer > button.modal-submit {
            background-color: #171c8f; }
          .vekalapp-modal .vekalapp-modal-container-div .vekalapp-modal-container .modal-footer > button.modal-cancel {
            background-color: #000; }
  .vekalapp-modal.kill-modal {
    animation: vekalapp-un-blur 0.3s 1 forwards; }
    .vekalapp-modal.kill-modal .vekalapp-modal-container-div {
      animation: vekalapp-scale-down 0.3s 1 forwards cubic-bezier(0.49, -0.6, 0.615, 0.085); }

@use 'sass:selector';
.vekalapp-breadcrumb {
  display: flex;
  align-items: center; }
  .vekalapp-breadcrumb .breadcrumb-items {
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center; }
    .vekalapp-breadcrumb .breadcrumb-items i {
      font-size: 10px;
      color: #7c7d9a;
      margin-left: 9px;
      margin-right: 5px; }
    .vekalapp-breadcrumb .breadcrumb-items a {
      color: #7c7d9a;
      font-size: 12px; }

@use 'sass:selector';
.vekalapp-popover {
  z-index: 100000000; }
  .vekalapp-popover .vekalapp-popover2-button {
    background-color: transparent;
    border: none;
    outline: none; }
    .vekalapp-popover .vekalapp-popover2-button.active {
      color: #00a3e0; }

.vekalapp-popover2-body {
  margin-top: -10px;
  position: relative;
  z-index: 10000000000;
  animation: vekalapp-popover2-animation 1 0.3s forwards cubic-bezier(0.475, 1.455, 0.705, 1.65);
  transform-origin: top right; }
  .vekalapp-popover2-body p {
    margin: 0; }
  .vekalapp-popover2-body.kill-animation {
    animation: vekalapp-popover2-kill-animation 1 0.3s cubic-bezier(0.49, -0.6, 0.615, 0.085); }

@keyframes vekalapp-popover2-animation {
  0% {
    transform: scale(0.8);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes vekalapp-popover2-kill-animation {
  0% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.8);
    opacity: 0; } }

@use 'sass:selector';
@media (min-width: 767.98px) {
  .vekalapp-timepicker-modal {
    display: none !important; } }

.vekalapp-time-picker {
  width: 100%; }
  .vekalapp-time-picker .tm-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer;
    background-color: transparent !important; }
  .vekalapp-time-picker .time-picker-index {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    height: 42px;
    border-radius: 5px;
    border: none !important;
    cursor: pointer;
    font-size: 13px;
    margin-top: 10px;
    background-color: #f1f2f5;
    font-weight: 300;
    padding: 10px 15px;
    transition: color 0.3s; }
    .vekalapp-time-picker .time-picker-index > * {
      transition: all 0.3s; }
    .vekalapp-time-picker .time-picker-index .spacer {
      flex: 1; }
    .vekalapp-time-picker .time-picker-index::placeholder {
      color: #b0b1c6 !important;
      font-size: 14px !important;
      font-weight: normal !important; }
    .vekalapp-time-picker .time-picker-index span {
      color: #b0b1c6 !important;
      font-size: 14px !important;
      font-weight: normal !important; }
    .vekalapp-time-picker .time-picker-index p {
      text-align: right;
      color: #25275d; }
    .vekalapp-time-picker .time-picker-index i {
      color: #494a67; }
    .vekalapp-time-picker .time-picker-index .time-picker-index-show {
      display: flex; }
      .vekalapp-time-picker .time-picker-index .time-picker-index-show p {
        margin-top: 4px;
        text-align: right;
        color: #25275d;
        font-size: 14px !important;
        font-weight: normal !important; }
  .vekalapp-time-picker.selecting .dp-label {
    color: #25275d !important;
    font-size: 16px !important;
    font-weight: normal !important; }
  .vekalapp-time-picker.selecting .time-picker-index {
    border: none !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .vekalapp-time-picker.selecting .time-picker-index .placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important;
      font-weight: normal !important; }
    .vekalapp-time-picker.selecting .time-picker-index::placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important; }
    .vekalapp-time-picker.selecting .time-picker-index span {
      opacity: 1 !important; }
  .vekalapp-time-picker.selected .time-picker-index .dp-label {
    color: #25275d !important;
    font-size: 16px !important;
    font-weight: normal !important; }
  .vekalapp-time-picker.error .time-picker-index {
    border: solid 2px #ff678c; }
  .vekalapp-time-picker.disable {
    cursor: not-allowed; }
    .vekalapp-time-picker.disable .time-picker-index {
      border-color: #cacbd9;
      pointer-events: none; }
      .vekalapp-time-picker.disable .time-picker-index > * {
        color: #cacbd9; }
      .vekalapp-time-picker.disable .time-picker-index .time-picker-index-show p {
        color: #9596b3; }
  .vekalapp-time-picker .error-text {
    color: #ff678c;
    margin: 6px 4px 0 0;
    text-align: right;
    font-size: 13px; }

.vekalapp-time-picker-content .time-picker-info {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #25275d;
  font-size: 26px; }

.vekalapp-time-picker-content h4 {
  font-size: 14px;
  font-weight: normal;
  margin: 30px 0 20px;
  color: #171c8f; }
  .vekalapp-time-picker-content h4:nth-child(2) {
    margin-top: 0px; }

.vekalapp-time-picker-content .rangeslider .rangeslider__handle {
  margin-right: -30px; }
  .vekalapp-time-picker-content .rangeslider .rangeslider__handle:after {
    background-color: unset;
    box-shadow: unset; }

.vekalapp-time-picker-content .rangeslider .rangeslider__fill {
  background-color: #171c8f; }

.vekalapp-time-picker-menu {
  display: none;
  padding: 16px;
  z-index: 100000;
  background-color: white;
  width: fit-content;
  transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
  border: none !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  opacity: 0;
  flex-direction: column;
  box-shadow: 0 20px 42px 0 rgba(22, 23, 52, 0.08);
  max-height: 0;
  overflow: hidden; }
  @media (min-width: 767.98px) {
    .vekalapp-time-picker-menu {
      display: flex; } }
  .vekalapp-time-picker-menu.open {
    max-width: unset;
    opacity: 1;
    max-height: 250px; }

/**
* Rangeslider
*/
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}
/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #7cb342;
  border-radius: 10px;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}
.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}
.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}
.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}
.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}
/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}
.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}
/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative;
}
.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: '';
  width: 10px;
  height: 2px;
  background: black;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}

@use 'sass:selector';
.vekalapp-date-picker-modal button {
  display: flex;
  align-items: center;
  justify-content: center; }

@media (min-width: 767.98px) {
  .vekalapp-date-picker-modal {
    display: none !important; } }

.date-picker-dropdown-select-menu {
  display: none;
  z-index: 100000 !important;
  max-height: 0;
  width: fit-content;
  min-width: 320px;
  overflow: hidden;
  transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
  border: none !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  opacity: 0;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 20px 42px 20px rgba(22, 23, 52, 0.08); }
  @media (min-width: 767.98px) {
    .date-picker-dropdown-select-menu {
      display: flex; } }
  .date-picker-dropdown-select-menu.open {
    max-width: unset;
    max-height: 400px;
    overflow: auto;
    opacity: 1; }

.vekalapp-date-picker-dropdown-calendar-container {
  border-radius: 5px;
  min-width: 300px;
  display: block; }
  .vekalapp-date-picker-dropdown-calendar-container .calendarContainer {
    box-shadow: none; }
  .vekalapp-date-picker-dropdown-calendar-container * {
    text-align: center !important;
    font-size: 15px; }
  .vekalapp-date-picker-dropdown-calendar-container .selectToday {
    display: none; }
  .vekalapp-date-picker-dropdown-calendar-container .dayPickerContainer {
    box-shadow: none; }
    .vekalapp-date-picker-dropdown-calendar-container .dayPickerContainer .selected button {
      background-color: rgba(0, 163, 224, 0.2);
      color: #00a3e0; }
    .vekalapp-date-picker-dropdown-calendar-container .dayPickerContainer .today button {
      border-color: #00a3e0 !important; }

/* dropdown */
.vekalapp-datepicker-dropdown {
  width: 100%;
  direction: rtl; }
  .vekalapp-datepicker-dropdown .dp-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-datepicker-dropdown .index {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    height: 42px;
    border-radius: 5px;
    border: none !important;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    background-color: #f1f2f5;
    padding: 10px 15px;
    font-weight: normal;
    transition: color 0.3s; }
    .vekalapp-datepicker-dropdown .index > * {
      transition: all 0.3s; }
    .vekalapp-datepicker-dropdown .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important;
      font-weight: normal !important; }
    .vekalapp-datepicker-dropdown .index .spacer {
      flex: 1; }
    .vekalapp-datepicker-dropdown .index .placeholder {
      opacity: 1;
      font-size: 13px !important;
      color: #b0b1c6 !important;
      font-weight: normal !important; }
    .vekalapp-datepicker-dropdown .index p {
      text-align: right;
      color: #25275d;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .vekalapp-datepicker-dropdown .index i {
      color: #25275d !important; }
  .vekalapp-datepicker-dropdown.selecting .dp-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important; }
  .vekalapp-datepicker-dropdown.selecting .index {
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .vekalapp-datepicker-dropdown.selecting .index .placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important;
      font-weight: normal !important; }
    .vekalapp-datepicker-dropdown.selecting .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important; }
    .vekalapp-datepicker-dropdown.selecting .index span {
      opacity: 1 !important; }
  .vekalapp-datepicker-dropdown.selected .dp-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important; }
  .vekalapp-datepicker-dropdown.selected .index {
    border: none; }
    .vekalapp-datepicker-dropdown.selected .index .placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important;
      font-weight: normal !important; }
    .vekalapp-datepicker-dropdown.selected .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important; }
  .vekalapp-datepicker-dropdown.error .index {
    border: solid 2px #ff678c !important; }
  .vekalapp-datepicker-dropdown.disable {
    cursor: not-allowed; }
    .vekalapp-datepicker-dropdown.disable .index {
      border-color: #cacbd9;
      pointer-events: none; }
      .vekalapp-datepicker-dropdown.disable .index > * {
        color: #cacbd9; }
  .vekalapp-datepicker-dropdown .error-text {
    color: #ff678c;
    margin: 6px 4px 0 0;
    text-align: right;
    font-size: 13px; }

/* fallback */
@font-face {
    font-family: 'Material Icons Sharp';
    font-style: normal;
    font-weight: 400;
    src: url(./oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvR.woff2) format('woff2');
}

.material-icons-sharp {
    font-family: 'Material Icons Sharp' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* fallback */
@font-face {
    font-family: 'Material Icons Round';
    font-style: normal;
    font-weight: 400;
    src: url(./LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2) format('woff2');
}

.material-icons-round {
    font-family: 'Material Icons Round' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* fallback */
@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url(./gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
@use 'sass:selector';
.vekalapp-header {
  display: flex;
  width: 100%;
  align-items: center;
  height: 80px;
  position: sticky;
  flex-direction: row;
  background-color: #ffffff;
  z-index: 10000; }
  @media (max-width: 575.98px) {
    .vekalapp-header {
      padding-left: 24px; } }
  @media (min-width: 575.98px) {
    .vekalapp-header {
      padding-left: 49.33333px; } }
  @media (min-width: 767.98px) {
    .vekalapp-header {
      padding-left: 62px; } }
  @media (min-width: 991.98px) {
    .vekalapp-header {
      padding-left: 74.66667px; } }
  @media (min-width: 1199.98px) {
    .vekalapp-header {
      padding-left: 100px; } }
  @media (max-width: 575.98px) {
    .vekalapp-header {
      padding-right: 24px; } }
  @media (min-width: 575.98px) {
    .vekalapp-header {
      padding-right: 49.33333px; } }
  @media (min-width: 767.98px) {
    .vekalapp-header {
      padding-right: 62px; } }
  @media (min-width: 991.98px) {
    .vekalapp-header {
      padding-right: 74.66667px; } }
  @media (min-width: 1199.98px) {
    .vekalapp-header {
      padding-right: 100px; } }
  .vekalapp-header iframe {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0; }
  .vekalapp-header .logo {
    display: flex;
    justify-content: center;
    align-items: center; }
    .vekalapp-header .logo svg {
      width: 180px;
      height: 50px; }

@keyframes vekalapp_pending_header {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }
  .vekalapp-header .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    animation: vekalapp_pending_header 4s linear infinite; }
    @media (max-width: 575.98px) {
      .vekalapp-header .loading {
        min-width: 100px; } }
    @media (min-width: 575.98px) {
      .vekalapp-header .loading {
        min-width: 116px; } }
    @media (min-width: 767.98px) {
      .vekalapp-header .loading {
        min-width: 124px; } }
    @media (min-width: 991.98px) {
      .vekalapp-header .loading {
        min-width: 132px; } }
    @media (min-width: 1199.98px) {
      .vekalapp-header .loading {
        min-width: 148px; } }
    @media (max-width: 575.98px) {
      .vekalapp-header .loading {
        max-width: 100px; } }
    @media (min-width: 575.98px) {
      .vekalapp-header .loading {
        max-width: 116px; } }
    @media (min-width: 767.98px) {
      .vekalapp-header .loading {
        max-width: 124px; } }
    @media (min-width: 991.98px) {
      .vekalapp-header .loading {
        max-width: 132px; } }
    @media (min-width: 1199.98px) {
      .vekalapp-header .loading {
        max-width: 148px; } }
  .vekalapp-header .items {
    display: flex;
    align-items: center;
    border-right: solid 1px #f1f2f5; }
    @media (max-width: 575.98px) {
      .vekalapp-header .items {
        padding-left: 10px; } }
    @media (min-width: 575.98px) {
      .vekalapp-header .items {
        padding-left: 14.66667px; } }
    @media (min-width: 767.98px) {
      .vekalapp-header .items {
        padding-left: 17px; } }
    @media (min-width: 991.98px) {
      .vekalapp-header .items {
        padding-left: 19.33333px; } }
    @media (min-width: 1199.98px) {
      .vekalapp-header .items {
        padding-left: 24px; } }
    @media (max-width: 575.98px) {
      .vekalapp-header .items {
        padding-right: 10px; } }
    @media (min-width: 575.98px) {
      .vekalapp-header .items {
        padding-right: 14.66667px; } }
    @media (min-width: 767.98px) {
      .vekalapp-header .items {
        padding-right: 17px; } }
    @media (min-width: 991.98px) {
      .vekalapp-header .items {
        padding-right: 19.33333px; } }
    @media (min-width: 1199.98px) {
      .vekalapp-header .items {
        padding-right: 24px; } }
    @media (max-width: 575.98px) {
      .vekalapp-header .items {
        margin-left: -8px; } }
    @media (min-width: 575.98px) {
      .vekalapp-header .items {
        margin-left: -17.33333px; } }
    @media (min-width: 767.98px) {
      .vekalapp-header .items {
        margin-left: -22px; } }
    @media (min-width: 991.98px) {
      .vekalapp-header .items {
        margin-left: -26.66667px; } }
    @media (min-width: 1199.98px) {
      .vekalapp-header .items {
        margin-left: -36px; } }
    .vekalapp-header .items::selection {
      display: none; }
    .vekalapp-header .items button, .vekalapp-header .items a {
      text-align: right;
      color: #626380;
      cursor: pointer;
      transition: color 0.3s; }
      @media (max-width: 575.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          font-size: 9px; } }
      @media (min-width: 575.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          font-size: 10px; } }
      @media (min-width: 767.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          font-size: 10.5px; } }
      @media (min-width: 991.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          font-size: 11px; } }
      @media (min-width: 1199.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          font-size: 12px; } }
      @media (max-width: 575.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          margin-left: 8px; } }
      @media (min-width: 575.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          margin-left: 17.33333px; } }
      @media (min-width: 767.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          margin-left: 22px; } }
      @media (min-width: 991.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          margin-left: 26.66667px; } }
      @media (min-width: 1199.98px) {
        .vekalapp-header .items button, .vekalapp-header .items a {
          margin-left: 36px; } }
      .vekalapp-header .items button.active, .vekalapp-header .items a.active {
        color: #171c8f;
        border-bottom: 1px solid #171c8f;
        padding-bottom: 12px; }
      .vekalapp-header .items button.active-link, .vekalapp-header .items a.active-link {
        color: #171c8f;
        border-bottom: 1px solid #171c8f;
        padding-bottom: 12px; }
      .vekalapp-header .items button:focus, .vekalapp-header .items button:hover, .vekalapp-header .items a:focus, .vekalapp-header .items a:hover {
        color: #00a3e0; }
      .vekalapp-header .items button::selection, .vekalapp-header .items a::selection {
        display: none; }
  .vekalapp-header span {
    flex: 1; }
  .vekalapp-header .items {
    display: flex; }
  .vekalapp-header .end-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 120px; }
    .vekalapp-header .end-button .vekalapp-button {
      height: 45px;
      font-size: 16px;
      font-weight: normal; }
  .vekalapp-header .search {
    border-radius: 5px;
    border: solid 1px #cacbd9;
    display: flex;
    align-items: center;
    max-width: 415px;
    height: 42px;
    width: 100%;
    padding: 0 14px; }
    .vekalapp-header .search input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      color: #7c7d9a; }
      .vekalapp-header .search input::placeholder {
        font-size: 12px;
        color: #cacbd9; }
    .vekalapp-header .search .search-icon {
      color: #cacbd9;
      font-size: 22px; }
  .vekalapp-header .search-icon {
    font-size: 26px;
    color: #25275d;
    cursor: pointer;
    transition: color 0.3s;
    margin-left: 10px; }
    .vekalapp-header .search-icon:hover {
      color: #7c7d9a; }
  .vekalapp-header .my-btn {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    background-color: #f1f2f5;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .vekalapp-header .my-btn:nth-child(1) {
      color: #4ec3e0; }
      .vekalapp-header .my-btn:nth-child(1) i {
        font-size: 25px;
        font-weight: normal; }
        .vekalapp-header .my-btn:nth-child(1) i::selection {
          display: none; }
    .vekalapp-header .my-btn i::selection {
      display: none; }
    .vekalapp-header .my-btn:nth-child(2) {
      color: #161734; }
      .vekalapp-header .my-btn:nth-child(2) i {
        font-size: 25px; }

.vekalapp-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #161734;
  z-index: 1000000;
  height: 80px; }
  .vekalapp-footer .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    font-weight: normal; }
    .vekalapp-footer .items i {
      margin-bottom: 7px;
      font-size: 28px; }
    .vekalapp-footer .items p {
      font-size: 10px; }
    .vekalapp-footer .items.active {
      color: #00a3e0; }

.vekalapp-profile-options-popover {
  border-radius: 10px;
  backdrop-filter: blur(30px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  padding: 16px 10px; }
  .vekalapp-profile-options-popover .items {
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 12px;
    color: #626380;
    font-size: 14px;
    font-weight: bold; }
    .vekalapp-profile-options-popover .items:hover {
      color: #171c8f; }

@use 'sass:selector';
.vekalapp-header-tools {
  width: 100%;
  display: flex;
  border-radius: 10px;
  backdrop-filter: blur(30px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16); }
  .vekalapp-header-tools .index {
    padding: 16px 10px;
    background-color: white;
    border-radius: 10px;
    width: 100%; }
    .vekalapp-header-tools .index .items {
      display: flex;
      width: 100%;
      padding: 8px 12px;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 6px; }
      .vekalapp-header-tools .index .items .context {
        display: flex;
        flex-direction: column; }
        .vekalapp-header-tools .index .items .context .title {
          display: flex;
          align-items: center;
          color: #626380;
          margin-bottom: 3px; }
          .vekalapp-header-tools .index .items .context .title p {
            font-size: 12px;
            font-weight: bold; }
          .vekalapp-header-tools .index .items .context .title i {
            font-size: 16px;
            margin-left: 4px; }
        .vekalapp-header-tools .index .items .context span {
          font-size: 10px;
          color: #7c7d9a; }
      .vekalapp-header-tools .index .items .spacer {
        flex: 1; }
      .vekalapp-header-tools .index .items .left {
        margin-right: 24px;
        font-size: 18px;
        color: #00a3e0; }
      .vekalapp-header-tools .index .items.active {
        background-color: #f1f2f5; }
        .vekalapp-header-tools .index .items.active .context .title {
          color: #00a3e0; }
  .vekalapp-header-tools .detail {
    padding: 16px 24px;
    background-color: #f1f2f5;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    min-width: 134px; }
    .vekalapp-header-tools .detail span {
      flex: 1; }
    .vekalapp-header-tools .detail .items-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%; }
      .vekalapp-header-tools .detail .items-container .items {
        font-size: 12px;
        color: #626380;
        margin-bottom: 18px;
        transition: color 0.3s; }
        .vekalapp-header-tools .detail .items-container .items:hover {
          color: #171c8f; }
      .vekalapp-header-tools .detail .items-container .more {
        display: flex;
        width: 100%;
        color: #00a3e0;
        transition: color 0.3s; }
        .vekalapp-header-tools .detail .items-container .more:hover {
          color: #25275d; }
        .vekalapp-header-tools .detail .items-container .more p {
          font-size: 14px; }
        .vekalapp-header-tools .detail .items-container .more i {
          font-size: 18px; }

@use 'sass:selector';
.vekalapp-login-modal .popup-content {
  background-color: #f1f2f5 !important; }

.vekalapp-login-modal .second-layer {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .vekalapp-login-modal .second-layer p {
    font-size: 18px;
    font-weight: normal;
    color: #25275d;
    text-align: center;
    margin-bottom: 26px; }
  .vekalapp-login-modal .second-layer input[type=number]::-webkit-inner-spin-button,
  .vekalapp-login-modal .second-layer input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none; }
  .vekalapp-login-modal .second-layer input[type=number] {
    -moz-appearance: textfield; }
  .vekalapp-login-modal .second-layer .otpContainer {
    direction: ltr;
    margin: 0 -8px;
    box-sizing: border-box; }
    .vekalapp-login-modal .second-layer .otpContainer .otpInput {
      outline: none;
      border: none;
      border-radius: 5px;
      margin: 0 8px;
      text-align: center;
      height: 60px;
      width: 60px;
      font-size: 20px;
      font-weight: normal;
      color: #25275d; }
      .vekalapp-login-modal .second-layer .otpContainer .otpInput:focus {
        color: #4ec3e0;
        border: 0.5px solid #4ec3e0; }
  .vekalapp-login-modal .second-layer .vekalapp-button {
    margin-top: 26px; }

.vekalapp-login-modal .first-layer .vekalapp-button {
  margin-top: 26px; }

@use 'sass:selector';
.vekalapp-header-sources {
  width: 100%;
  display: flex;
  border-radius: 10px;
  backdrop-filter: blur(30px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16); }
  .vekalapp-header-sources .index {
    padding: 16px 10px;
    background-color: white;
    border-radius: 10px;
    width: 100%; }
    .vekalapp-header-sources .index .items {
      display: flex;
      width: 100%;
      padding: 8px 12px;
      align-items: center;
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 6px; }
      .vekalapp-header-sources .index .items .context {
        display: flex;
        flex-direction: column; }
        .vekalapp-header-sources .index .items .context .title {
          display: flex;
          align-items: center;
          color: #626380;
          margin-bottom: 3px; }
          .vekalapp-header-sources .index .items .context .title p {
            font-size: 12px;
            font-weight: bold; }
          .vekalapp-header-sources .index .items .context .title i {
            font-size: 16px;
            margin-left: 4px; }
        .vekalapp-header-sources .index .items .context span {
          font-size: 10px;
          color: #7c7d9a; }
      .vekalapp-header-sources .index .items .spacer {
        flex: 1; }
      .vekalapp-header-sources .index .items .left {
        margin-right: 24px;
        font-size: 18px;
        color: #00a3e0; }
      .vekalapp-header-sources .index .items.active {
        background-color: #f1f2f5; }
        .vekalapp-header-sources .index .items.active .context .title {
          color: #00a3e0; }
  .vekalapp-header-sources .detail {
    padding: 16px 24px;
    background-color: #f1f2f5;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    min-width: 134px; }
    .vekalapp-header-sources .detail span {
      flex: 1; }
    .vekalapp-header-sources .detail .items-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%; }
      .vekalapp-header-sources .detail .items-container .items {
        font-size: 12px;
        color: #626380;
        margin-bottom: 18px;
        transition: color 0.3s; }
        .vekalapp-header-sources .detail .items-container .items:hover {
          color: #171c8f; }
      .vekalapp-header-sources .detail .items-container .more {
        display: flex;
        width: 100%;
        color: #00a3e0;
        transition: color 0.3s; }
        .vekalapp-header-sources .detail .items-container .more:hover {
          color: #25275d; }
        .vekalapp-header-sources .detail .items-container .more p {
          font-size: 14px; }
        .vekalapp-header-sources .detail .items-container .more i {
          font-size: 18px; }

@use 'sass:selector';
.vekalapp-multi-value-input {
  position: relative;
  width: 100%; }
  .vekalapp-multi-value-input .ti-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-multi-value-input .multi-value-input-container {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    border-radius: 4px;
    flex-wrap: wrap;
    overflow: auto;
    flex-direction: row;
    cursor: default;
    min-height: 42px;
    margin-top: 10px; }
    .vekalapp-multi-value-input .multi-value-input-container > input {
      width: 100%;
      height: 100%;
      font-size: 16px;
      text-align: right;
      box-sizing: border-box;
      border: none;
      outline: none;
      color: #626380; }
      .vekalapp-multi-value-input .multi-value-input-container > input.has-value {
        margin-top: 16px; }
      .vekalapp-multi-value-input .multi-value-input-container > input::placeholder {
        opacity: 1 !important;
        color: #b0b1c6 !important;
        font-size: 14px !important;
        font-weight: normal !important; }
      .vekalapp-multi-value-input .multi-value-input-container > input:disabled {
        background-color: #f1f2f5; }
    .vekalapp-multi-value-input .multi-value-input-container .value-items {
      display: flex;
      align-items: center;
      flex-direction: row;
      border: solid 1px #cacbd9;
      background-color: #f1f2f5;
      border-radius: 5px;
      padding: 4px;
      margin: 8px 0 0 8px; }
      .vekalapp-multi-value-input .multi-value-input-container .value-items p {
        text-align: right;
        color: #25275d;
        margin: 0;
        padding: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px; }
      .vekalapp-multi-value-input .multi-value-input-container .value-items i {
        cursor: pointer;
        margin-right: 2px;
        color: #7c7d9a;
        padding: 3px;
        border-radius: 100%;
        font-size: 16px;
        transition: background-color 0.3s, color 0.3s; }
        .vekalapp-multi-value-input .multi-value-input-container .value-items i:hover {
          color: #25275d;
          background-color: rgba(202, 203, 217, 0.3); }
  .vekalapp-multi-value-input.error > label {
    top: -9px;
    background-color: #fff;
    font-size: 12px;
    padding: 0 2px;
    cursor: default; }
  .vekalapp-multi-value-input.error input::placeholder {
    opacity: 0.3; }
  .vekalapp-multi-value-input.error .input-error {
    margin: 0;
    color: #ff678c;
    text-align: right;
    font-size: 12px;
    font-weight: bold; }
  .vekalapp-multi-value-input.error input {
    border-color: #ff678c !important; }
  .vekalapp-multi-value-input.error > label {
    color: #ff678c; }

@use 'sass:selector';
.vekalapp-cost-picker {
  position: relative;
  width: 100%; }
  .vekalapp-cost-picker .cp-label {
    color: #25275d !important;
    font-size: 16px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-cost-picker input {
    width: 100%;
    text-align: right;
    box-sizing: border-box;
    border: none !important;
    cursor: text;
    font-size: 13px;
    margin-top: 16px;
    background-color: #f1f2f5;
    height: 42px;
    font-weight: normal;
    padding: 10px 15px;
    border-radius: 4px;
    color: #25275d;
    outline: none; }
    .vekalapp-cost-picker input::placeholder {
      opacity: 1 !important;
      color: #b0b1c6 !important;
      font-size: 13px !important;
      font-weight: normal !important; }
  .vekalapp-cost-picker.active .cp-label, .vekalapp-cost-picker.error .cp-label {
    color: #25275d !important;
    font-size: 16px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-cost-picker.active input::placeholder, .vekalapp-cost-picker.error input::placeholder {
    opacity: 1 !important;
    color: #b0b1c6 !important;
    font-size: 13px !important;
    font-weight: normal !important; }
  .vekalapp-cost-picker.error .input-error {
    margin: 0;
    color: #ff678c;
    text-align: right;
    font-size: 12px;
    font-weight: normal; }
  .vekalapp-cost-picker.error input {
    border: 1px solid #ff678c !important; }

@use 'sass:selector';
.vekalapp-bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: white;
  z-index: 50000;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -10px 84px 0 rgba(22, 23, 52, 0.05); }
  .vekalapp-bottom-navigation .bottom-navigation-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #25275d;
    flex: 1; }
    .vekalapp-bottom-navigation .bottom-navigation-item > i {
      font-size: 22px;
      color: #25275d; }
    .vekalapp-bottom-navigation .bottom-navigation-item > i.active {
      font-size: 24px;
      background-color: #4ec3e0;
      color: white;
      border-radius: 50%;
      border: white 4px solid;
      min-width: 56px;
      min-height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -42px; }
    .vekalapp-bottom-navigation .bottom-navigation-item > p {
      margin-top: 6px;
      color: #25275d;
      font-size: 12px; }
      .vekalapp-bottom-navigation .bottom-navigation-item > p.active {
        color: #4ec3e0; }

@use 'sass:selector';

@use 'sass:selector';
.vekalapp-file-attachment {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column; }
  .vekalapp-file-attachment .fa-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-file-attachment .fa-content {
    display: flex;
    padding: 12px 18px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    background-color: #f1f2f5;
    margin-top: 10px;
    height: 42px;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
    justify-content: center; }
    .vekalapp-file-attachment .fa-content.disabled {
      cursor: not-allowed; }
    .vekalapp-file-attachment .fa-content p {
      font-size: 14px;
      font-weight: normal;
      color: #25275d;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .vekalapp-file-attachment .fa-content .spacer {
      display: flex;
      flex: 1;
      width: 100%; }
    .vekalapp-file-attachment .fa-content i {
      font-weight: normal;
      font-size: 18px;
      color: #25275d;
      margin-right: 6px; }
    .vekalapp-file-attachment .fa-content .lds-ellipsis {
      display: flex;
      position: relative;
      width: 80px;
      height: 80px;
      justify-content: center;
      align-items: center; }
    .vekalapp-file-attachment .fa-content .lds-ellipsis div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #25275d;
      animation-timing-function: cubic-bezier(0, 1, 1, 0); }
    .vekalapp-file-attachment .fa-content .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite; }
    .vekalapp-file-attachment .fa-content .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite; }
    .vekalapp-file-attachment .fa-content .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite; }
    .vekalapp-file-attachment .fa-content .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }
  .vekalapp-file-attachment input {
    display: none; }
  .vekalapp-file-attachment.active .fa-content, .vekalapp-file-attachment.error .fa-content {
    border: solid 1px #ff678c !important; }
  .vekalapp-file-attachment.active .ti-label, .vekalapp-file-attachment.error .ti-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-file-attachment .input-error {
    margin: 0;
    color: #ff678c;
    text-align: right;
    font-size: 12px;
    font-weight: normal; }

.vekalapp-preview-picture .popup-container {
  overflow: auto; }

.vekalapp-preview-picture .vekalapp-file-attachment-image-preview {
  width: 100%;
  height: 100%; }
  .vekalapp-preview-picture .vekalapp-file-attachment-image-preview > img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

@use 'sass:selector';
@media (min-width: 767.98px) {
  .vekalapp-colorpicker-dropdown-modal {
    display: none !important; } }

.vekalapp-colorpicker-dropdown-modal .vekalapp-modal-container {
  margin: 16px; }

.vekalapp-colorpicker-dropdown-modal .modal-context-div {
  display: flex;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 767.98px) {
    .vekalapp-colorpicker-dropdown-modal .modal-context-div {
      display: none; } }
  .vekalapp-colorpicker-dropdown-modal .modal-context-div > * {
    width: 100%;
    text-align: center; }
  .vekalapp-colorpicker-dropdown-modal .modal-context-div input {
    height: 40px;
    direction: rtl;
    padding: 0 12px;
    width: 90%;
    margin: 10px auto;
    border: none;
    border-bottom: solid 1px #7c7d9a;
    outline: none;
    transition: border 0.3s;
    color: #25275d;
    font-size: 15px;
    font-weight: 400; }
    .vekalapp-colorpicker-dropdown-modal .modal-context-div input:hover {
      border-bottom: solid 1px #25275d; }
      .vekalapp-colorpicker-dropdown-modal .modal-context-div input:hover::placeholder {
        color: #25275d; }
  .vekalapp-colorpicker-dropdown-modal .modal-context-div .items-container {
    max-height: 150px;
    overflow: auto; }
    .vekalapp-colorpicker-dropdown-modal .modal-context-div .items-container .items {
      padding: 10px 12px;
      cursor: pointer;
      color: #494a67;
      font-size: 16px;
      font-weight: 400; }
      .vekalapp-colorpicker-dropdown-modal .modal-context-div .items-container .items:hover {
        background-color: #f1f2f5; }
    .vekalapp-colorpicker-dropdown-modal .modal-context-div .items-container .no-result {
      text-align: right;
      padding: 0 12px;
      color: #494a67;
      font-size: 14px;
      font-weight: 400; }

.vekalapp-colorpicker-dropdown {
  width: 100%;
  direction: rtl; }
  .vekalapp-colorpicker-dropdown .dd-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer;
    background-color: transparent !important; }
  .vekalapp-colorpicker-dropdown .index {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    height: 42px;
    border-radius: 5px;
    border: none !important;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    background-color: #f1f2f5;
    font-weight: 300;
    padding: 10px 15px;
    transition: color 0.3s; }
    .vekalapp-colorpicker-dropdown .index > * {
      transition: all 0.3s; }
    .vekalapp-colorpicker-dropdown .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 14px !important;
      font-weight: normal !important; }
    .vekalapp-colorpicker-dropdown .index .spacer {
      flex: 1; }
    .vekalapp-colorpicker-dropdown .index .placeholder {
      opacity: 1;
      font-size: 14px !important;
      color: #b0b1c6 !important;
      font-weight: normal !important; }
    .vekalapp-colorpicker-dropdown .index p {
      text-align: right;
      color: #25275d;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .vekalapp-colorpicker-dropdown .index i {
      color: #25275d !important; }
  .vekalapp-colorpicker-dropdown.selecting .dd-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important; }
  .vekalapp-colorpicker-dropdown.selecting .index {
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .vekalapp-colorpicker-dropdown.selecting .index .placeholder {
      color: #b0b1c6 !important;
      font-size: 14px !important;
      font-weight: normal !important; }
    .vekalapp-colorpicker-dropdown.selecting .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 14px !important; }
    .vekalapp-colorpicker-dropdown.selecting .index span {
      opacity: 1 !important; }
  .vekalapp-colorpicker-dropdown.selected .dd-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important; }
  .vekalapp-colorpicker-dropdown.selected .index {
    border: none; }
    .vekalapp-colorpicker-dropdown.selected .index .placeholder {
      color: #b0b1c6 !important;
      font-size: 14px !important;
      font-weight: normal !important; }
    .vekalapp-colorpicker-dropdown.selected .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 14px !important; }
  .vekalapp-colorpicker-dropdown.error .index {
    border: solid 1px #ff678c !important; }
  .vekalapp-colorpicker-dropdown.disable {
    cursor: not-allowed; }
    .vekalapp-colorpicker-dropdown.disable .index {
      border-color: #cacbd9;
      pointer-events: none; }
      .vekalapp-colorpicker-dropdown.disable .index > * {
        color: #cacbd9; }
  .vekalapp-colorpicker-dropdown .error-text {
    color: #ff678c;
    margin-top: 0;
    text-align: right;
    font-size: 12px;
    font-weight: normal; }

.colorpicker-dropdown-select-menu {
  display: none;
  z-index: 100000 !important;
  max-height: 0;
  width: fit-content;
  overflow: hidden;
  transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
  border: none !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  opacity: 0;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 20px 42px 0 rgba(22, 23, 52, 0.08); }
  @media (min-width: 767.98px) {
    .colorpicker-dropdown-select-menu {
      display: flex; } }
  .colorpicker-dropdown-select-menu.open {
    max-width: unset;
    max-height: 250px;
    overflow: auto;
    opacity: 1;
    border: none; }
  .colorpicker-dropdown-select-menu.active {
    border: none !important; }
  .colorpicker-dropdown-select-menu input {
    height: 40px;
    direction: rtl;
    padding: 10px 15px !important;
    width: 100% !important;
    margin: 0 auto;
    border-bottom: solid 1px #e4e4e4 !important;
    outline: none;
    transition: border 0.3s;
    color: #25275d;
    font-size: 14px !important;
    font-weight: 300; }
    .colorpicker-dropdown-select-menu input::placeholder {
      font-size: 14px;
      font-weight: 300; }
    .colorpicker-dropdown-select-menu input:hover {
      border-bottom: solid 1px #25275d; }
      .colorpicker-dropdown-select-menu input:hover::placeholder {
        color: #25275d; }
  .colorpicker-dropdown-select-menu .items-container {
    padding: 10px 15px; }
    .colorpicker-dropdown-select-menu .items-container .items {
      padding: 10px 15px !important;
      cursor: pointer;
      color: #25275d;
      font-size: 13px !important;
      font-weight: 300;
      text-align: right; }
      .colorpicker-dropdown-select-menu .items-container .items:hover {
        background-color: #f1f2f5; }
    .colorpicker-dropdown-select-menu .items-container .no-result {
      text-align: right;
      padding: 0 12px;
      color: #494a67;
      font-size: 14px;
      font-weight: 400; }

@use 'sass:selector';
.vekalapp-basic-input {
  width: 100%;
  direction: rtl; }
  .vekalapp-basic-input .dd-label {
    color: #25275d !important;
    font-size: 16px !important;
    font-weight: normal !important;
    cursor: pointer;
    background-color: transparent !important; }
  .vekalapp-basic-input .index {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    height: 42px;
    border-radius: 5px;
    border: none !important;
    cursor: pointer;
    font-size: 13px;
    margin-top: 16px;
    background-color: #f1f2f5;
    font-weight: 300;
    padding: 10px 15px;
    transition: color 0.3s; }
    .vekalapp-basic-input .index .placeholder {
      opacity: 1;
      font-size: 13px !important;
      color: #b0b1c6 !important;
      font-weight: normal !important; }
    .vekalapp-basic-input .index .value {
      text-align: right;
      color: #25275d;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .vekalapp-basic-input .index i {
      color: #25275d !important; }
      .vekalapp-basic-input .index i:first-child {
        margin-left: 12px; }
      .vekalapp-basic-input .index i:last-child {
        margin-right: 12px; }
  .vekalapp-basic-input.selecting .dd-label {
    color: #25275d !important;
    font-size: 16px !important;
    font-weight: normal !important; }
  .vekalapp-basic-input.selecting .index {
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .vekalapp-basic-input.selecting .index .placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important;
      font-weight: normal !important; }
    .vekalapp-basic-input.selecting .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important; }
    .vekalapp-basic-input.selecting .index span {
      opacity: 1 !important; }
  .vekalapp-basic-input.selected .dd-label {
    color: #25275d !important;
    font-size: 16px !important;
    font-weight: normal !important; }
  .vekalapp-basic-input.selected .index {
    border: none; }
    .vekalapp-basic-input.selected .index .placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important;
      font-weight: normal !important; }
    .vekalapp-basic-input.selected .index::placeholder {
      color: #b0b1c6 !important;
      font-size: 13px !important; }
  .vekalapp-basic-input.error .index {
    border: solid 1px #ff678c !important; }
  .vekalapp-basic-input.disable {
    cursor: not-allowed; }
    .vekalapp-basic-input.disable .index {
      border-color: #cacbd9;
      pointer-events: none; }
      .vekalapp-basic-input.disable .index > * {
        color: #cacbd9; }
  .vekalapp-basic-input .error-text {
    color: #ff678c;
    margin: 6px 4px 0 0;
    text-align: right;
    font-size: 13px;
    font-weight: normal; }

@use 'sass:selector';
.vekalapp-text-area-input {
  position: relative;
  width: 100%; }
  .vekalapp-text-area-input .ti-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-text-area-input textarea {
    width: 100%;
    text-align: right;
    box-sizing: border-box;
    border: none !important;
    cursor: text;
    font-size: 14px;
    background-color: #f1f2f5;
    margin-top: 10px;
    height: 42px;
    font-weight: normal;
    padding: 10px 15px;
    border-radius: 4px;
    color: #25275d;
    outline: none;
    height: 130px;
    resize: none; }
    .vekalapp-text-area-input textarea::placeholder {
      opacity: 1 !important;
      color: #b0b1c6 !important;
      font-size: 14px !important;
      font-weight: normal !important; }
    .vekalapp-text-area-input textarea:disabled {
      cursor: not-allowed; }
  .vekalapp-text-area-input.active .ti-label, .vekalapp-text-area-input.error .ti-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-text-area-input.active input::placeholder, .vekalapp-text-area-input.error input::placeholder {
    opacity: 1 !important;
    color: #b0b1c6 !important;
    font-size: 13px !important;
    font-weight: normal !important; }
  .vekalapp-text-area-input.error .input-error {
    margin: 0;
    color: #ff678c;
    text-align: right;
    font-size: 12px;
    font-weight: normal; }
  .vekalapp-text-area-input.error .index {
    border: solid 1px #ff678c !important; }
  .vekalapp-text-area-input.error textarea {
    border: 1px solid #ff678c !important; }

@use 'sass:selector';
.vekalapp-text-editor-input {
  position: relative;
  width: 100%; }
  .vekalapp-text-editor-input > div:first-child {
    width: 100%;
    display: flex;
    align-items: center; }
    .vekalapp-text-editor-input > div:first-child .ti-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #25275d !important;
      font-size: 14px !important;
      font-weight: normal !important;
      margin-left: 12px;
      cursor: pointer; }
    .vekalapp-text-editor-input > div:first-child > button {
      display: flex;
      border: none;
      outline: none;
      width: 36px;
      height: 36px;
      border-radius: 5px;
      background-color: #f1f2f5;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      color: #25275d;
      margin-left: 6px; }
      .vekalapp-text-editor-input > div:first-child > button.active {
        background-color: #4ec3e0;
        color: #ffffff; }
  .vekalapp-text-editor-input > div:nth-child(2).disabled {
    cursor: not-allowed; }
  .vekalapp-text-editor-input .DraftEditor-root {
    padding: 14px 18px;
    background-color: #f1f2f5;
    border-radius: 5px;
    min-height: 182px;
    margin-top: 10px; }
    .vekalapp-text-editor-input .DraftEditor-root .public-DraftEditorPlaceholder-root {
      font-size: 13px !important;
      font-weight: normal !important;
      color: #b0b1c6 !important; }
  .vekalapp-text-editor-input.active > div .ti-label, .vekalapp-text-editor-input.error > div .ti-label {
    color: #25275d !important;
    font-size: 14px !important;
    font-weight: normal !important;
    cursor: pointer; }
  .vekalapp-text-editor-input.error .input-error {
    margin: 0;
    color: #ff678c;
    text-align: right;
    font-size: 12px;
    font-weight: normal; }
  .vekalapp-text-editor-input.error .DraftEditor-root {
    border: solid 1px #ff678c !important;
    color: #25275d;
    font-size: 14px; }

@use 'sass:selector';
.vekalapp-share-modal .popup-content p {
  font-weight: 400;
  font-size: 14px;
  color: #25275d; }

.vekalapp-share-modal .popup-content .copy-link {
  width: 100%;
  border-radius: 8px;
  border: solid 1px black;
  height: 40px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .vekalapp-share-modal .popup-content .copy-link > label {
    font-size: 12px;
    font-weight: 400;
    color: #494a67;
    margin-right: 6px; }
  .vekalapp-share-modal .popup-content .copy-link > i {
    font-size: 16px;
    color: #494a67; }

.vekalapp-share-modal .popup-content .social-row {
  display: flex;
  align-items: center;
  margin-bottom: 16px; }
  .vekalapp-share-modal .popup-content .social-row > .item {
    border-radius: 8px;
    cursor: pointer;
    flex: 1;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px; }
    .vekalapp-share-modal .popup-content .social-row > .item > label {
      font-size: 12px;
      font-weight: 400;
      color: white;
      margin-right: 6px; }
    .vekalapp-share-modal .popup-content .social-row > .item > img {
      width: 16px;
      height: 16px;
      object-fit: cover; }
    .vekalapp-share-modal .popup-content .social-row > .item > i {
      color: white; }
    .vekalapp-share-modal .popup-content .social-row > .item:last-child {
      margin-left: 0; }
    .vekalapp-share-modal .popup-content .social-row > .item.whatsapp {
      background-color: #25d366; }
      .vekalapp-share-modal .popup-content .social-row > .item.whatsapp > img {
        filter: invert(1); }
    .vekalapp-share-modal .popup-content .social-row > .item.twitter {
      background-color: #00acee; }
    .vekalapp-share-modal .popup-content .social-row > .item.facebook {
      background-color: #3b5998; }
    .vekalapp-share-modal .popup-content .social-row > .item.sms {
      background-color: #ff678c; }
    .vekalapp-share-modal .popup-content .social-row > .item.linkedin {
      background-color: #0072b1; }
    .vekalapp-share-modal .popup-content .social-row > .item.telegram {
      background-color: #0088cc; }
      .vekalapp-share-modal .popup-content .social-row > .item.telegram > img {
        filter: invert(1);
        width: 22px;
        height: 22px; }
      .vekalapp-share-modal .popup-content .social-row > .item.telegram > label {
        margin-right: 2px; }
