@mixin vekalapp-xs {
    @media (max-width: 575.98px) {
        @content
    }
}

;

@mixin vekalapp-sm {
    @media (min-width: 575.98px) {
        @content
    }
}

;

@mixin vekalapp-md {
    @media (min-width: 767.98px) {
        @content
    }
}

;

@mixin vekalapp-lg {
    @media (min-width: 991.98px) {
        @content
    }
}

;

@mixin vekalapp-xl {
    @media (min-width: 1199.98px) {
        @content
    }
}

;

@mixin vekalapp-pre-xs {
    @media (max-width: 575.98px) {
        @content
    }
}

;

@mixin vekalapp-pre-sm {
    @media (max-width: 575.98px) {
        @content
    }
}

;

@mixin vekalapp-pre-md {
    @media (max-width: 767.98px) {
        @content
    }
}

;

@mixin vekalapp-pre-lg {
    @media (max-width: 991.98px) {
        @content
    }
}

;

@mixin vekalapp-pre-xl {
    @media (max-width: 1199.98px) {
        @content
    }
}

;

@mixin vekalapp-size($property, $web-value, $mobile-value, $is-important: false) {
    @include vekalapp-xs {
        @if ($is-important) {
            #{$property}: $mobile-value !important;
        } @else {
            #{$property}: $mobile-value;
        }
    }

    @include vekalapp-sm {
        @if ($is-important) {
            #{$property}: (2 * $mobile-value + $web-value)/3 !important;
        } @else {
            #{$property}: (2 * $mobile-value + $web-value)/3;
        }
    }

    @include vekalapp-md {
        @if ($is-important) {
            #{$property}: ($mobile-value + $web-value)/2 !important;
        } @else {
            #{$property}: ($mobile-value + $web-value)/2;
        }
    }

    @include vekalapp-lg {
        @if ($is-important) {
            #{$property}: ($mobile-value + 2 * $web-value)/3 !important;
        } @else {
            #{$property}: ($mobile-value + 2 * $web-value)/3;
        }
    }

    @include vekalapp-xl {
        @if ($is-important) {
            #{$property}: $web-value !important;
        } @else {
            #{$property}: $web-value;
        }
    }
}
;
